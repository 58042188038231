import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  * {
    box-sizing: border-box;
  }
  body, html {
    min-height: 100vh;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-top: 35px;
    font-family: ${theme.fontFamily};
    color: ${theme.color.fontDefaultColor};
  }
  #app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .clickable {
    cursor: pointer;
  }
  p {
    margin: 0;
  }
  ul {
    padding: 0;
  }
  h1 {
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
    margin: 0 50px 50px;
    text-align: center;
    color: ${theme.color.BriefcaseColor};
  }
  h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin: 0 10px 10px;
  }
  h3 {
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    margin: 10px 10px 36px;
  }
  a {
    color: ${theme.color.linkColor};
    text-decoration: none;
    font-weight: 500;
    outline: none;
  }
  .marked {
    color: ${theme.color.BriefcaseColor};
  }
  button + button,
  a + button {
    margin-left: 30px;
  }
  .margin-50 {
    margin: 50px;
  }
`;
