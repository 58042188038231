import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Flex } from '@rebass/grid';
import React from 'react';

const LoadingIndicator = (props) => (
  <Flex justifyContent="center" style={props.style}>
    <Box my={10}>
      <CircularProgress {...props} />
    </Box>
  </Flex>
);

export default LoadingIndicator;
