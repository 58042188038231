import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import { unregister } from './registerServiceWorker';
import { store, history, persistor } from './store';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';

// Do not use service worker!
// registerServiceWorker();
unregister();

const render = () => {
  ReactDOM.render(
    <ErrorBoundary history={history}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App history={history} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('app')
  );
};
render();

if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}
