import { push } from 'connected-react-router';
import API from '../config/api';
import apiRoutes from '../config/apiRoutes';
import { APP_ROUTES } from '../constants/routes';
import {
  SUBSCRIPTIONS_ADD_CARD_FAILURE,
  SUBSCRIPTIONS_ADD_CARD_REQUEST,
  SUBSCRIPTIONS_ADD_CARD_SUCCESS,
  SUBSCRIPTIONS_CREATE_SUBSCRIPTION_FAILURE,
  SUBSCRIPTIONS_CREATE_SUBSCRIPTION_REQUEST,
  SUBSCRIPTIONS_CREATE_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTIONS_DELETE_CARD_FAILURE,
  SUBSCRIPTIONS_DELETE_CARD_REQUEST,
  SUBSCRIPTIONS_DELETE_CARD_SUCCESS,
  SUBSCRIPTIONS_FETCH_CARDS_FAILURE,
  SUBSCRIPTIONS_FETCH_CARDS_REQUEST,
  SUBSCRIPTIONS_FETCH_CARDS_SUCCESS,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_FAILURE,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_REQUEST,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_SUCCESS,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_FAILURE,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_REQUEST,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SUCCESS,
  SUBSCRIPTIONS_SELECT_SUBSCRIPTION_PLAN,
  SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_FAILURE,
  SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_REQUEST,
  SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_SUCCESS,
  SUBSCRIPTIONS_UNSUBSCRIBE_FAILURE,
  SUBSCRIPTIONS_UNSUBSCRIBE_REQUEST,
  SUBSCRIPTIONS_UNSUBSCRIBE_SUCCESS
} from './actionTypes';

export const fetchSubscriptionPlansRequest = () => ({
  type: SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_REQUEST,
});

export const fetchSubscriptionPlansSuccess = (payload) => ({
  type: SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_SUCCESS,
  payload,
});

export const fetchSubscriptionPlansFailure = (error) => ({
  type: SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_FAILURE,
  error,
});

export const fetchSubscriptionPlans = () => async (dispatch) => {
  try {
    dispatch(fetchSubscriptionPlansRequest());
    const { data } = await API(apiRoutes.subscriptionPlans);
    dispatch(fetchSubscriptionPlansSuccess(data));
  } catch (error) {
    dispatch(fetchSubscriptionPlansFailure(error));
  }
};

export const createSubscriptionRequest = () => ({
  type: SUBSCRIPTIONS_CREATE_SUBSCRIPTION_REQUEST,
});

export const createSubscriptionSuccess = (payload) => ({
  type: SUBSCRIPTIONS_CREATE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const createSubscriptionFailure = (error) => ({
  type: SUBSCRIPTIONS_CREATE_SUBSCRIPTION_FAILURE,
  error,
});

export const fetchSubscriptionsRequest = () => ({
  type: SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_REQUEST,
});

export const fetchSubscriptionsSuccess = (payload) => ({
  type: SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const fetchSubscriptionsFailure = (error) => ({
  type: SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_FAILURE,
  error,
});

export const fetchSubscriptions = (forceRefetch = false) => async (dispatch, getState) => {
  const { subscriptions: { subscriptionsFetched } } = getState();
  if (subscriptionsFetched && !forceRefetch) {
    return;
  }
  try {
    dispatch(fetchSubscriptionsRequest());
    const { data } = await API.get(apiRoutes.userSubscriptions);
    dispatch(fetchSubscriptionsSuccess(data));
  } catch (error) {
    dispatch(fetchSubscriptionsFailure(error));
  }
};


export const createSubscription = (payload) => async (dispatch, getState) => {
  try {
    dispatch(createSubscriptionRequest());
    const subscriptionPlanId = getState().subscriptions.selectedPlan.internalId;
    const { data } = await API.post(apiRoutes.subscriptions, { ...payload, subscriptionPlanId });
    dispatch(createSubscriptionSuccess(data));
    await dispatch(fetchSubscriptions(true));
    dispatch(push(APP_ROUTES.subscriptionSuccess));
  } catch (error) {
    dispatch(createSubscriptionFailure(error));
    dispatch(push(APP_ROUTES.subscriptionFailure));
  }
};

export const selectSubscriptionPlan = (subscriptionPlan) => ({
  type: SUBSCRIPTIONS_SELECT_SUBSCRIPTION_PLAN,
  subscriptionPlan,
});

export const fetchCardsRequest = () => ({
  type: SUBSCRIPTIONS_FETCH_CARDS_REQUEST,
});

export const fetchCardsSuccess = (payload) => ({
  type: SUBSCRIPTIONS_FETCH_CARDS_SUCCESS,
  payload,
});

export const fetchCardsFailure = (error) => ({
  type: SUBSCRIPTIONS_FETCH_CARDS_FAILURE,
  error,
});

export const fetchCards = () => async (dispatch) => {
  try {
    dispatch(fetchCardsRequest());
    const { data } = await API.get(apiRoutes.cards);
    dispatch(fetchCardsSuccess(data));
  } catch (error) {
    dispatch(fetchCardsFailure(error));
  }
};

export const addCardRequest = () => ({
  type: SUBSCRIPTIONS_ADD_CARD_REQUEST,
});

export const addCardSuccess = (payload) => ({
  type: SUBSCRIPTIONS_ADD_CARD_SUCCESS,
  payload,
});

export const addCardFailure = (error) => ({
  type: SUBSCRIPTIONS_ADD_CARD_FAILURE,
  error,
});

export const addCard = ({ token }) => async (dispatch) => {
  try {
    dispatch(addCardRequest());
    const { data } = await API.post(apiRoutes.cards, { token });
    dispatch(addCardSuccess(data));
    dispatch(push(APP_ROUTES.updateCard));
  } catch (error) {
    dispatch(addCardFailure(error));
    dispatch(push(APP_ROUTES.somethingWentWrong));
  }
};

export const deleteCardRequest = () => ({
  type: SUBSCRIPTIONS_DELETE_CARD_REQUEST,
});

export const deleteCardSuccess = (payload) => ({
  type: SUBSCRIPTIONS_DELETE_CARD_SUCCESS,
  payload,
});

export const deleteCardFailure = (error) => ({
  type: SUBSCRIPTIONS_DELETE_CARD_FAILURE,
  error,
});

export const deleteCard = ({ cardId }) => async (dispatch) => {
  try {
    dispatch(deleteCardRequest());
    const { data } = await API.delete(`${apiRoutes.cards}/${cardId}`);
    dispatch(deleteCardSuccess(data));
    dispatch(push(APP_ROUTES.deleteCard));
  } catch (error) {
    dispatch(deleteCardFailure(error));
  }
};

export const setCardAsDefaultRequest = () => ({
  type: SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_REQUEST,
});

export const setCardAsDefaultSuccess = (payload) => ({
  type: SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_SUCCESS,
  payload,
});

export const setCardAsDefaultFailure = (error) => ({
  type: SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_FAILURE,
  error,
});

export const setCardAsDefault = ({ cardId }) => async (dispatch) => {
  try {
    dispatch(setCardAsDefaultRequest());
    const { data } = await API.post(`${apiRoutes.defaultCard}/${cardId}`);
    dispatch(setCardAsDefaultSuccess(data));
  } catch (error) {
    dispatch(setCardAsDefaultFailure(error));
  }
};

export const unsubscribeRequest = () => ({
  type: SUBSCRIPTIONS_UNSUBSCRIBE_REQUEST,
});

export const unsubscribeSuccess = (payload) => ({
  type: SUBSCRIPTIONS_UNSUBSCRIBE_SUCCESS,
  payload,
});

export const unsubscribeFailure = (error) => ({
  type: SUBSCRIPTIONS_UNSUBSCRIBE_FAILURE,
  error,
});

export const unsubscribe = () => async (dispatch) => {
  try {
    dispatch(unsubscribeRequest());
    const { data } = await API.post(apiRoutes.stripeUnsubscribe);
    dispatch(unsubscribeSuccess(data));
    await dispatch(fetchSubscriptions(true));
    dispatch(push(APP_ROUTES.settings));
  } catch (error) {
    dispatch(unsubscribeFailure(error));
    dispatch(push(APP_ROUTES.somethingWentWrong));
  }
};
