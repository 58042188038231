export default {
  fontFamily: 'proxima-nova, sans-serif',
  color: {
    BriefcaseColor: '#530C74', // primary       purple
    fontOnDarkBgColor: '#fff', // secondary     white
    fontDefaultColor: '#505A6B', // secondary   dark grey
    fontMinorColor: '#9CA2AB', // secondary     grey
    headerBg: '#505A6B', // secondary           dark grey
    headerDarkBg: '#3C475A', // secondary       darker grey
    linkColor: '#1E88E5', // primary            blue
    buttonPrimaryBg: '#1E88E5', // primary      blue
    buttonSecondaryBg: 'transparent',
    buttonFormBg: '#07BF24', // success         green
    buttonDisabledBg: 'transparent',
    formField: '#1E88E5', // primary            blue
    formFieldError: '#FF3D00', // error         red
    pageLightBg: '#fff', // secondary           white
    pageDarkBg: '#F0F0F0', // secondary         almost white
    boxLightBg: '#fff', // secondary            white
    highlight: '#2FAFFF', // primary            light blue
    borderLine: '#D5D5D5', // secondary         light grey
    borderColor: '#F0F0F0', // out of Guideline light grey
    mobileMenuBgColor: '#3C475A', // out of G.  light grey
    subscriptionBorder: '#04CB39', // green
    subscriptionFailure: '#E64A19',
  },
  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16)',
  boxShadowAlt: '0 0 6px 0 rgba(0,0,0,0.48)',
  breakpoints: ['567px', '768px', '960px', '1200px']
};
