import React from 'react';
import { Flex, Box } from '@rebass/grid';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { APP_ROUTES } from '../../constants/routes';
import { media } from '../../utils/styleUtils';

const FixedContainer = styled(Flex)`
  position: fixed;
  align-items: flex-end;
  top: 70px;
  left: 0;
  right: 0;
  height: 55px;
  z-index: 50;
  background-color: ${(props) => props.theme.color.pageLightBg};
  box-shadow: ${(props) => props.theme.boxShadow};
`;
const MenuItem = styled(NavLink)`
  color: ${(props) => props.theme.color.fontDefaultColor};
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  ${media.sm`
    display: none;
  `}
`;
const MenuItemIcon = styled(({
  icon, last, children, ...rest
}) => (
  <MenuItem {...rest}>
    {children}
  </MenuItem>
))`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  :before {
    position: absolute;
    top: -50%;
    transform: translateY(-50%);
    content: "";
    width: 25px;
    height: 25px;
    background-image: url('/images/icons/${({ icon }) => icon}-off.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 23px;
    border: ${({ last }) => (last ? 'none' : '1px solid #fff')};
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  :hover:before,
  &.active:before {
    width: 25px;
    height: 25px;
    border: none;
    background-color: #3c475a;
    background-image: url('/images/icons/${({ icon }) => icon}-on.svg');
    background-size: 23px;
  }
`;
const Wrapper = styled(Flex)`
  display: none;
  ${media.sm`
    display: block;
  `};
`;

const MobileAuthSubMenu = ({ hideDashboardIcon }) => (
  <Wrapper>
    <Box pt={[55, 55, 0]} />
    <FixedContainer justifyContent="space-around">
      <MenuItemIcon
        icon="icon-mynews"
        to={APP_ROUTES.myNews}
      >
        My&nbsp;News
      </MenuItemIcon>
      <MenuItemIcon
        icon="icon-briefings"
        to={APP_ROUTES.myBriefingsBase}
      >
        Briefings
      </MenuItemIcon>
      <MenuItemIcon
        icon="icon-companies"
        to={APP_ROUTES.companiesBase}
      >
        Companies
      </MenuItemIcon>
      {!hideDashboardIcon && (
        <MenuItemIcon
          icon="icon-line-chart"
          to={APP_ROUTES.dashboard}
        >
          Dashboard
        </MenuItemIcon>
      )}
      <MenuItemIcon
        icon="icon-search"
        to={APP_ROUTES.search}
      >
        Search
      </MenuItemIcon>
      <MenuItemIcon
        icon="icon-settings"
        to={APP_ROUTES.settings}
        last
      >
        Settings
      </MenuItemIcon>
    </FixedContainer>
  </Wrapper>
);

export default MobileAuthSubMenu;
