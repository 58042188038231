import {
  HASHTAG_EMAIL_NOTIFICATIONS_OFF,
  HASHTAG_EMAIL_NOTIFICATIONS_ON,
  HASHTAG_FETCHING,
  HASHTAG_FETCHING_FAILURE,
  HASHTAG_FETCHING_SUCCESS,
  HASHTAG_FOLLOW,
  HASHTAG_NOTIFICATIONS_UPDATING,
  HASHTAG_NOTIFICATIONS_UPDATING_FAILURE,
  HASHTAG_UNFOLLOW,
  HASHTAG_PUSH_NOTIFICATIONS_OFF,
  HASHTAG_PUSH_NOTIFICATIONS_ON
} from '../actions/actionTypes';

const initialState = {
  item: {},
  isFetching: false,
  isUpdating: false,
  error: null
};

const fetchingData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingSuccess = (state, { payload }) => ({
  ...state,
  isFetching: false,
  item: { ...payload }
});

const fetchingFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});

const updateStart = (state) => ({
  ...state,
  isUpdating: true
});

const updateFailure = (state) => ({
  ...state,
  isUpdating: false
});

const toggleFollow = (state, follow) => ({
  ...state,
  isUpdating: false,
  item: {
    ...state.item,
    follow
  }
});

/* eslint-disable camelcase */

const toggleEmail = (state, email_notification) => ({
  ...state,
  isUpdating: false,
  item: {
    ...state.item,
    email_notification
  }
});

const togglePush = (state, push_notification) => ({
  ...state,
  isUpdating: false,
  item: {
    ...state.item,
    push_notification
  }
});

const selectEmail = (state) => toggleEmail(state, true);
const unselectEmail = (state) => toggleEmail(state, false);

const selectPush = (state) => togglePush(state, true);
const unselectPush = (state) => togglePush(state, false);

const follow = (state) => toggleFollow(state, true);
const unfollow = (state) => toggleFollow(state, false);

export default (state = initialState, action) => {
  switch (action.type) {
    case HASHTAG_NOTIFICATIONS_UPDATING:
      return updateStart(state, action);

    case HASHTAG_NOTIFICATIONS_UPDATING_FAILURE:
      return updateFailure(state, action);

    case HASHTAG_UNFOLLOW:
      return unfollow(state, action);

    case HASHTAG_FOLLOW:
      return follow(state, action);

    case HASHTAG_PUSH_NOTIFICATIONS_OFF:
      return unselectPush(state, action);

    case HASHTAG_PUSH_NOTIFICATIONS_ON:
      return selectPush(state, action);

    case HASHTAG_EMAIL_NOTIFICATIONS_OFF:
      return unselectEmail(state, action);

    case HASHTAG_EMAIL_NOTIFICATIONS_ON:
      return selectEmail(state, action);

    case HASHTAG_FETCHING:
      return fetchingData(state, action);

    case HASHTAG_FETCHING_SUCCESS:
      return fetchingSuccess(state, action);

    case HASHTAG_FETCHING_FAILURE:
      return fetchingFailure(state, action);

    default:
      return state;
  }
};
