import { push } from 'connected-react-router';
import {
  LOGIN_SUCCES,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT_USER,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  SECURE_LINK_REQUEST,
  SECURE_LINK_SUCCESS,
  SECURE_LINK_FAILURE,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  RESET_PASSWORD_EMAIL_REQUEST,
  RESET_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_EMAIL_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  COPY_EMAIL_TO_SECURE_LINK_PAGE,
} from './actionTypes';
import { fetchUserInfo } from './account';
import { fetchSubscriptions } from './subscriptions';
import API, { setAuthenticationToken } from '../config/api';
import apiRoutes from '../config/apiRoutes';
import { bootIntercom, shutdownIntercom } from '../intercom';
import { APP_ROUTES } from '../constants/routes';

export const copyEmailToSecureLinkPage = (email) => ({
  type: COPY_EMAIL_TO_SECURE_LINK_PAGE,
  email,
});

export const login = (payload = null) => async (dispatch, getState) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const response = await API.post(apiRoutes.signIn, payload);
    setAuthenticationToken(response.data.token);
    dispatch({ type: LOGIN_SUCCES });
    await dispatch(fetchUserInfo());
    dispatch(fetchSubscriptions());

    bootIntercom(getState());
  } catch (e) {
    dispatch({
      type: LOGIN_FAILURE,
      errors: e.errors
    });
  }
};

export const loginWithMagicLink = (magicToken) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const response = await API.post(apiRoutes.loginWithMagicLink, { token: magicToken });
    setAuthenticationToken(response.data.token);
    dispatch({ type: LOGIN_SUCCES });
    dispatch(fetchUserInfo());
    dispatch(push(APP_ROUTES.myNews));
  } catch ({ errors }) {
    dispatch({
      type: LOGIN_FAILURE,
      errors,
    });
    dispatch(push(APP_ROUTES.login));
  }
};

export const secureLink = ({ email }) => async (dispatch) => {
  try {
    dispatch({ type: SECURE_LINK_REQUEST });
    await API.post(apiRoutes.secureLink, { email });
    dispatch({ type: SECURE_LINK_SUCCESS, email });
    dispatch(push(APP_ROUTES.secureLinkSuccess));
  } catch ({ errors }) {
    dispatch({ type: SECURE_LINK_FAILURE, errors });
  }
};

export const setPassword = (form, token) => async (dispatch) => {
  try {
    dispatch({ type: SET_PASSWORD_REQUEST });
    const response = await API.post(apiRoutes.setPassword, { token, ...form });
    dispatch({ type: SET_PASSWORD_SUCCESS });
    setAuthenticationToken(response.data.token);
    dispatch({ type: LOGIN_SUCCES });
    dispatch(fetchUserInfo());
    dispatch(push(APP_ROUTES.myNews));
  } catch ({ errors }) {
    dispatch({ type: SET_PASSWORD_FAILURE, errors });
  }
};

export const resetPassword = (form, token) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const response = await API.post(apiRoutes.changePasswordUniversalLink, { token, ...form });
    dispatch({ type: RESET_PASSWORD_SUCCESS });
    if (response.data.token) {
      setAuthenticationToken(response.data.token);
      dispatch({ type: LOGIN_SUCCES });
      dispatch(fetchUserInfo());
      dispatch(push(APP_ROUTES.myNews));
    }
  } catch ({ errors }) {
    dispatch({ type: RESET_PASSWORD_FAILURE, errors });
  }
};

export const logout = () => (dispatch) => {
  shutdownIntercom();

  window.localStorage.removeItem('token');
  window.localStorage.removeItem('firstName');
  window.localStorage.removeItem('email');
  delete API.defaults.headers.common.Authorization;
  dispatch({
    type: LOGOUT_USER
  });
};

export const changePassword = (form) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });
    await API.post(apiRoutes.changePassword, form);
    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
    dispatch(push(APP_ROUTES.settings));
  } catch (error) {
    dispatch({ type: CHANGE_PASSWORD_FAILURE, errors: error.errors });
  }
};

export const resetPasswordEmailSend = (email) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_EMAIL_REQUEST });
    await API.post(apiRoutes.resetPassword, { email });
    dispatch({ type: RESET_PASSWORD_EMAIL_SUCCESS });
    dispatch(push(APP_ROUTES.resetPasswordEmailSent));
  } catch (error) {
    dispatch({ type: RESET_PASSWORD_EMAIL_FAILURE, errors: error.errors || error.message });
    dispatch(push(APP_ROUTES.resetPasswordEmailSent));
  }
};
