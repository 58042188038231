/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export const isUserAuthenticated = (ComposedComponent) => {
  class Authenticate extends Component {
    render() {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('token');
      const { isAuthenticated } = this.props.auth;
      if (isAuthenticated) {
        return <ComposedComponent {...this.props} />;
      }
      if (token) {
        return <ComposedComponent {...this.props} />;
      }
      return <Redirect to="/login" />;
    }
  }
  return connect((state) => ({ auth: state.auth }))(Authenticate);
};

export const redirectAuthenticatedUser = (ComposedComponent) => {
  class Authenticate extends Component {
    render() {
      const { isAuthenticated } = this.props.auth;
      if (!isAuthenticated) {
        return <ComposedComponent {...this.props} />;
      }
      return <Redirect to="/my-news" />;
    }
  }
  return connect((state) => ({ auth: state.auth }))(Authenticate);
};
