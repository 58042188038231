import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  AsyncCareersPage,
  AsyncCompareOurProductsPage,
  AsyncHowWeDoItPage,
  AsyncLoginPage,
  AsyncNews,
  AsyncRequestDemoPage,
  AsyncTeamPage,
  AsyncWhatWeDoPage,
  AsyncWhyBriefcasePage
} from '../../App';
import { APP_ROUTES } from '../../constants/routes';
import { media } from '../../utils/styleUtils';
import Button from '../common/Button';

const MobileMenuWrapper = styled.div`
  height: 0;
  overflow: hidden;
  ${media.sm`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: ${(props) => props.theme.color.mobileMenuBgColor};
    transition: background 0.20s linear, height 0.20s ease-in 200ms;
    height: 100%;
  `}
`;

const MobileList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  list-style: none;
  padding: 20px 20px 60px;
  margin: 0;
`;

const MobileListItem = styled.li`
  display: block;
`;

const MobileMenuTitle = styled.div`
  margin-bottom: 10px;
  color: ${(props) => props.theme.color.fontOnDarkBgColor};
`;

const MobileMenuLink = styled(({
  primary, spacious, children, ...rest
}) => (
  <Link {...rest}>{children}</Link>
))`
  display: block;
  font-size: ${(props) => (props.primary ? '16px' : '14px')};
  color: ${(props) => props.theme.color.fontOnDarkBgColor};
  ${({ spacious }) => (spacious ? 'margin: 10px;' : '')}
`;
const MobileLoginLink = styled(({
  primary, spacious, children, ...rest
}) => (
  <a {...rest}>{children}</a>
))`
  display: block;
  font-size: 14px;
  color: ${(props) => props.theme.color.fontOnDarkBgColor};
  margin: 10px;
`;

const MobileMenuClose = styled.div`
  width: 56px;
  height: 36px;
  padding: 10px 20px;
  background-image: url('/images/icons/icon-cross-white.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  text-indent: -9999px;
`;

const MobileMenu = ({
  isAuthenticated, toggleMobileMenu, toggleModal, onMouseOver
}) => (
  <MobileMenuWrapper>
    <MobileList>
      <MobileListItem>
        <MobileMenuTitle>Menu</MobileMenuTitle>
      </MobileListItem>
      <MobileListItem>
        <MobileMenuLink
          onClick={toggleMobileMenu}
          onMouseOver={onMouseOver(AsyncWhyBriefcasePage)}
          onFocus={onMouseOver(AsyncWhyBriefcasePage)}
          primary
          to={APP_ROUTES.base}
        >
          Why Briefcase
        </MobileMenuLink>
      </MobileListItem>
      <MobileListItem>
        <MobileMenuLink
          onClick={toggleMobileMenu}
          onMouseOver={onMouseOver(AsyncWhatWeDoPage)}
          onFocus={onMouseOver(AsyncWhatWeDoPage)}
          primary
          to={APP_ROUTES.whatWeDo}
        >
          What we do
        </MobileMenuLink>
      </MobileListItem>
      <MobileListItem>
        <MobileMenuLink
          onClick={toggleMobileMenu}
          onMouseOver={onMouseOver(AsyncHowWeDoItPage)}
          onFocus={onMouseOver(AsyncHowWeDoItPage)}
          primary
          to={APP_ROUTES.howWeDoIt}
        >
          How we do it
        </MobileMenuLink>
      </MobileListItem>
      <MobileListItem>
        <MobileMenuLink
          onClick={toggleMobileMenu}
          onMouseOver={onMouseOver(AsyncCompareOurProductsPage)}
          onFocus={onMouseOver(AsyncCompareOurProductsPage)}
          primary
          to={APP_ROUTES.compareOurProducts}
        >
          Products
        </MobileMenuLink>
      </MobileListItem>
      <MobileListItem>
        <MobileMenuLink
          onClick={toggleMobileMenu}
          onMouseOver={onMouseOver(AsyncTeamPage)}
          onFocus={onMouseOver(AsyncTeamPage)}
          primary
          to={APP_ROUTES.team}
        >
          Team
        </MobileMenuLink>
      </MobileListItem>
      <MobileListItem>
        <MobileMenuLink
          onClick={toggleMobileMenu}
          onMouseOver={onMouseOver(AsyncCareersPage)}
          onFocus={onMouseOver(AsyncCareersPage)}
          primary
          to={APP_ROUTES.careers}
        >
          Careers
        </MobileMenuLink>
      </MobileListItem>
      <MobileListItem>
        <MobileMenuLink
          onClick={toggleMobileMenu}
          onMouseOver={onMouseOver(AsyncNews)}
          onFocus={onMouseOver(AsyncNews)}
          primary
          to={APP_ROUTES.news}
        >
          News
        </MobileMenuLink>
      </MobileListItem>
      <MobileListItem>
        <MobileMenuLink onClick={toggleModal} primary to="#">Contact us</MobileMenuLink>
      </MobileListItem>
      {
        !isAuthenticated
        && (
          <>
            <MobileListItem>
              <Link
                onClick={toggleMobileMenu}
                onMouseOver={onMouseOver(AsyncRequestDemoPage)}
                onFocus={onMouseOver(AsyncRequestDemoPage)}
                to={APP_ROUTES.requestADemo}
              >
                <Button primary>Request a demo</Button>
              </Link>
            </MobileListItem>
            <MobileListItem>
              <MobileLoginLink
                onMouseOver={onMouseOver(AsyncLoginPage)}
                onFocus={onMouseOver(AsyncLoginPage)}
                href={process.env.REACT_APP_LOGIN_LINK}
              >
              Login
              </MobileLoginLink>
            </MobileListItem>
          </>
        )
      }
      <MobileListItem>
        <MobileMenuClose onClick={toggleMobileMenu}>Close</MobileMenuClose>
      </MobileListItem>
    </MobileList>
  </MobileMenuWrapper>
);

export default MobileMenu;
