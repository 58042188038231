import {
  MY_NEWS_FETCHING,
  MY_NEWS_FETCHING_FAILURE,
  MY_NEWS_FETCHING_SUCCESS,
  MY_NEWS_MORE_FETCHING,
  MY_NEWS_MORE_FETCHING_FAILURE,
  MY_NEWS_MORE_FETCHING_SUCCESS,
  MY_NEWS_NEW_CONTENT_AVAILABLE,
  MY_NEWS_NEW_CONTENT_INSERT,
} from '../actions/actionTypes';

export const mergeSections = (oldSections, sections, links) => {
  if (oldSections.length === 0 || sections.length === 0) {
    return null;
  }
  const lastSection = oldSections[oldSections.length - 1];
  const firstNewSection = sections[0];
  if (lastSection.hashtagId === firstNewSection.hashtagId) {
    const existedElements = oldSections.slice(0, oldSections.length - 1);
    const incomingElements = sections.slice(1, sections.length);

    const mergedSection = {
      ...lastSection,
      data: [...lastSection.data, ...firstNewSection.data]
    };

    return {
      isFetching: false,
      sections: [...existedElements, mergedSection, ...incomingElements],
      links
    };
  }

  return null;
};

const initialState = {
  sections: [],
  links: {},
  newContent: [],
  isFetching: false,
  firstRequest: true,
  error: null
};

const fetchingData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingSuccess = (state, { payload: { sections, links } }) => ({
  ...state,
  isFetching: false,
  sections: [...sections],
  firstRequest: false,
  links
});

const fetchingFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});

const fetchingMoreData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingMoreSuccess = (state, { payload: { sections, links } }) => {
  const merged = mergeSections(state.sections, sections, links);
  if (merged) {
    return {
      ...state,
      ...merged
    };
  }

  return {
    ...state,
    isFetching: false,
    sections: [...state.sections, ...sections],
    links
  };
};

const fetchingMoreFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});

const newContentAvailable = (state, { payload }) => ({
  ...state,
  newContent: payload,
});

const insertNewContent = (state) => ({
  ...state,
  ...state.newContent,
  newContent: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case MY_NEWS_FETCHING:
      return fetchingData(state, action);

    case MY_NEWS_FETCHING_SUCCESS:
      return fetchingSuccess(state, action);

    case MY_NEWS_FETCHING_FAILURE:
      return fetchingFailure(state, action);

    case MY_NEWS_MORE_FETCHING:
      return fetchingMoreData(state, action);

    case MY_NEWS_MORE_FETCHING_SUCCESS:
      return fetchingMoreSuccess(state, action);

    case MY_NEWS_MORE_FETCHING_FAILURE:
      return fetchingMoreFailure(state, action);

    case MY_NEWS_NEW_CONTENT_AVAILABLE:
      return newContentAvailable(state, action);

    case MY_NEWS_NEW_CONTENT_INSERT:
      return insertNewContent(state);

    default:
      return state;
  }
};
