import styled from 'styled-components';

const Button = styled.button`
  min-width: 164px;
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}px` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}px` : '')};
  padding: ${(props) => (props.small ? '9px 30px' : '13px 30px')};
  background: ${(props) => (props.primary ? props.theme.color.buttonPrimaryBg : props.theme.color.buttonSecondaryBg)};
  border: 2px solid ${(props) => (!props.primary ? props.theme.color.buttonPrimaryBg : props.theme.color.buttonSecondaryBg)};
  border-radius: ${(props) => (props.small ? '20px' : '25px')};
  color: ${(props) => (!props.primary ? props.theme.color.buttonPrimaryBg : '#fff')};
  line-height: ${(props) => (props.small ? '20px' : '22px')};
  font-size: ${(props) => (props.small ? '16px' : '18px')};
  font-weight: 500;
  outline: none;
  :hover {
    cursor: pointer;
  }
`;

export default Button;

export const FormButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? 'transparent' : props.theme.color.buttonFormBg)};
  border: 2px solid ${(props) => (props.disabled ? '#9ca2ab' : props.theme.color.buttonFormBg)};
  color: ${(props) => (props.disabled ? '#9ca2ab' : '#fff')};
  :hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
