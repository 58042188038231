import CookieConsent, { getCookieConsentValue, Cookies } from 'react-cookie-consent';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initializeThirdParty } from '../actions/app';

class CookieBanner extends Component {
  async componentDidMount() {
    if (getCookieConsentValue('cookieConsentDomain') === 'true') {
      this.props.initializeThirdParty();
    } else {
      console.log(Cookies.get());
      Object.keys(Cookies.get()).forEach((cookieName) => {
        Cookies.remove(cookieName);
      });
    }
  }

  render() {
    return (
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieConsentDomain"
        extraCookieOptions={{ domain: 'briefcase.news' }}
        style={{ background: '#505A6B' }}
        buttonStyle={{
          background: '#1E88E5', color: 'white', fontSize: '13px'
        }}
        onAccept={() => {
          Cookies.set('cookieConsentDomain', true, {
            expires: 150,
            domain: '.briefcase.news',
          });
          this.props.initializeThirdParty();
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    );
  }
}

const mapDispatchToProps = { initializeThirdParty };
export default connect(null, mapDispatchToProps)(CookieBanner);


