import {
  UNSUBSCRIBE,
  UNSUBSCRIBE_FAILURE,
  UNSUBSCRIBE_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  data: {},
  isFetching: false,
  error: null,
};

const fetchingData = (state) => ({
  ...state,
  isFetching: true,
  data: {}
});

const fetchingSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  isFetching: false,
});

const fetchingFailure = (state) => ({
  ...state,
  isFetching: false,
  data: {},
  error: true
});

export default (state = initialState, action) => {
  switch (action.type) {

    case UNSUBSCRIBE:
      return fetchingData(state, action);

    case UNSUBSCRIBE_SUCCESS:
      return fetchingSuccess(state, action);

    case UNSUBSCRIBE_FAILURE:
      return fetchingFailure(state, action);

    default:
      return state;
  }
};
