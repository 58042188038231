import {
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  employees: [],
  errors: {},
  isLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.employees,
        isLoading: false
      };
    default:
      return state;
  }
};
