import React from 'react';
import styled from 'styled-components';

export const DefaultError = styled.div`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.theme.color.formFieldError};
  margin-top: 3px;
  margin-bottom: ${(props) => props.mb}px;
`;

DefaultError.defaultProps = {
  fontSize: 12,
  mb: -16
};

const FormError = ({ meta }) => (
  <DefaultError>
    {meta.error
      || (typeof meta.submitError === 'string'
        ? meta.submitError
        : meta.submitError[0])}
  </DefaultError>
);

export default FormError;
