import {
  SETTINGS_ACCOUNT_USER_FETCHING,
  SETTINGS_ACCOUNT_USER_FETCHING_FAILURE,
  SETTINGS_ACCOUNT_USER_FETCHING_SUCCESS,
  SETTINGS_ACCOUNT_USER_UPDATING,
  SETTINGS_ACCOUNT_USER_UPDATING_FAILURE,
  SETTINGS_ACCOUNT_USER_UPDATING_SUCCESS,
  SIGN_IN_ANONYMOUS_SUCCESS,
} from './actionTypes';
import API from '../config/api';
import apiRoutes from '../config/apiRoutes';

export const fetchUserInfo = () => async (dispatch) => {
  try {
    dispatch({ type: SETTINGS_ACCOUNT_USER_FETCHING });
    const { data } = await API.get(apiRoutes.userInfo);
    const { anonymous } = data;
    if (anonymous) {
      dispatch({ type: SIGN_IN_ANONYMOUS_SUCCESS });
    }
    dispatch({ type: SETTINGS_ACCOUNT_USER_FETCHING_SUCCESS, payload: data });
    localStorage.setItem('firstName', data.firstName);
    localStorage.setItem('email', data.email);
    localStorage.setItem('subscriptions', JSON.stringify(data.subscriptions.map((s) => s.access_to)));
  } catch (error) {
    dispatch({ type: SETTINGS_ACCOUNT_USER_FETCHING_FAILURE, error });
  }
};

export const updateUserInfo = (data) => async (dispatch) => {
  try {
    dispatch({ type: SETTINGS_ACCOUNT_USER_UPDATING });
    const { email, silent, ...dataFields } = data; // remove email and silent from request params
    const response = await API.post(apiRoutes.userInfoUpdate, dataFields);
    dispatch({ type: SETTINGS_ACCOUNT_USER_UPDATING_SUCCESS, payload: response.data.user });
  } catch (error) {
    dispatch({ type: SETTINGS_ACCOUNT_USER_UPDATING_FAILURE, error });
  }
};

export const toggleAllEmailNotifications = () => async (dispatch, getState) => {
  const {
    account: {
      data: { allEmailNotificationsEnabled }
    }
  } = getState();
  await dispatch(
    updateUserInfo({
      silent: true,
      allEmailNotificationsEnabled: !allEmailNotificationsEnabled
    })
  );
};

export const toggleAllPushNotifications = () => async (dispatch, getState) => {
  const {
    account: {
      data: { allPushNotificationsEnabled }
    }
  } = getState();
  await dispatch(
    updateUserInfo({
      silent: true,
      allPushNotificationsEnabled: !allPushNotificationsEnabled
    })
  );
};
