// JOB APPLICATION
export const FETCH_JOBS_REQUEST = 'FETCH_JOBS_REQUEST';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';

// STATIC PAGES FORMS
export const UNAUTH_FORM_REQUEST = 'UNAUTH_FORM_REQUEST';
export const UNAUTH_FORM_SUCCESS = 'UNAUTH_FORM_SUCCESS';
export const UNAUTH_FORM_FAILURE = 'UNAUTH_FORM_FAILURE';
export const UNAUTH_FORM_RESET = 'UNAUTH_FORM_RESET';

// AUTH
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCES = 'LOGIN_SUCCES';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const SECURE_LINK_REQUEST = 'SECURE_LINK_REQUEST';
export const SECURE_LINK_SUCCESS = 'SECURE_LINK_SUCCESS';
export const SECURE_LINK_FAILURE = 'SECURE_LINK_FAILURE';
export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_EMAIL_REQUEST = 'RESET_PASSWORD_EMAIL_REQUEST';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS';
export const RESET_PASSWORD_EMAIL_FAILURE = 'RESET_PASSWORD_EMAIL_FAILURE';
export const COPY_EMAIL_TO_SECURE_LINK_PAGE = 'COPY_EMAIL_TO_SECURE_LINK_PAGE';

// APP
export const RESET_STORED_DATA = 'RESET_STORED_DATA';
export const TOGGLE_CONTACT_US_MODAL = 'TOGGLE_CONTACT_US_MODAL';

// ANONYMOUS NEWS FOR STATIC NEWS PAGE
export const UNAUTH_NEWS_FETCHING = 'UNAUTH_NEWS_FETCHING';
export const UNAUTH_NEWS_FETCHING_SUCCESS = 'UNAUTH_NEWS_FETCHING_SUCCESS';
export const UNAUTH_NEWS_FETCHING_FAILURE = 'UNAUTH_NEWS_FETCHING_FAILURE';

// MY NEWS
export const MY_NEWS_FETCHING = 'MY_NEWS_FETCHING';
export const MY_NEWS_FETCHING_SUCCESS = 'MY_NEWS_FETCHING_SUCCESS';
export const MY_NEWS_NEW_CONTENT_INSERT = 'MY_NEWS_NEW_CONTENT_INSERT';
export const MY_NEWS_NEW_CONTENT_AVAILABLE = 'MY_NEWS_NEW_CONTENT_AVAILABLE';
export const MY_NEWS_FETCHING_FAILURE = 'MY_NEWS_FETCHING_FAILURE';
export const MY_NEWS_MORE_FETCHING = 'MY_NEWS_MORE_FETCHING';
export const MY_NEWS_MORE_FETCHING_SUCCESS = 'MY_NEWS_MORE_FETCHING_SUCCESS';
export const MY_NEWS_MORE_FETCHING_FAILURE = 'MY_NEWS_MORE_FETCHING_FAILURE';
export const MY_NEWS_RESET = 'MY_NEWS_RESET';

// DASHBOARD
export const DASHBOARD_FETCH_SELECTS_DATA = 'DASHBOARD_FETCH_SELECTS_DATA';
export const DASHBOARD_FETCH_SELECTS_DATA_SUCCESS = 'DASHBOARD_FETCH_SELECTS_DATA_SUCCESS';
export const DASHBOARD_FETCH_SELECTS_DATA_FAILURE = 'DASHBOARD_FETCH_SELECTS_DATA_FAILURE';
export const DASHBOARD_FETCH_CHART_DATA = 'DASHBOARD_FETCH_CHART_DATA';
export const DASHBOARD_FETCH_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_CHART_DATA_FAILURE';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA = 'DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA_FAILURE';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA_FAILURE';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA_FAILURE';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA_SUCCESS = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA_SUCCESS';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA_FAILURE = 'DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA_FAILURE';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA = 'DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_FAILURE';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA = 'DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA_FAILURE';
export const DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA = 'DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA';
export const DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_FAILURE';
export const DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA = 'DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA';
export const DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA_FAILURE';
export const UPDATE_PUBLICATION_BY_COMPANIES_FILTER = 'UPDATE_PUBLICATION_BY_COMPANIES_FILTER';
export const UPDATE_TOP_SOURCES_FILTER = 'UPDATE_TOP_SOURCES_FILTER';
export const UPDATE_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_FILTER = 'UPDATE_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_FILTER';
export const UPDATE_REQUEST_DETAILED_REPORT_FILTER = 'UPDATE_REQUEST_DETAILED_REPORT_FILTER';
export const DASHBOARD_REQUEST_DETAILED_REPORT_EMAIL = 'DASHBOARD_REQUEST_DETAILED_REPORT_EMAIL';
export const DASHBOARD_REQUEST_DETAILED_REPORT_EMAIL_SUCCESS = 'DASHBOARD_REQUEST_DETAILED_REPORT_EMAIL_SUCCESS';
export const DASHBOARD_REQUEST_DETAILED_REPORT_EMAIL_FAILURE = 'DASHBOARD_REQUEST_DETAILED_REPORT_EMAIL_FAILURE';
export const DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA = 'DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA';
export const DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA_SUCCESS = 'DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA_SUCCESS';
export const DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA_FAILURE = 'DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA_FAILURE';
export const UPDATE_SENTIMENT_FOR_COMPANIES_FILTER = 'UPDATE_SENTIMENT_FOR_COMPANIES_FILTER';

// BRIEFINGS
export const BRIEFINGS_LABELS_FETCHING = 'BRIEFINGS_LABELS_FETCHING';
export const BRIEFINGS_LABELS_SUCCESS = 'BRIEFINGS_LABELS_SUCCESS';
export const BRIEFINGS_LABELS_FAILURE = 'BRIEFINGS_LABELS_FAILURE';
export const BRIEFINGS_FETCHING = 'BRIEFINGS_FETCHING';
export const BRIEFINGS_FETCHING_SUCCESS = 'BRIEFINGS_FETCHING_SUCCESS';
export const BRIEFINGS_FETCHING_FAILURE = 'BRIEFINGS_FETCHING_FAILURE';
export const BRIEFINGS_MORE_FETCHING = 'BRIEFINGS_MORE_FETCHING';
export const BRIEFINGS_MORE_FETCHING_SUCCESS = 'BRIEFINGS_MORE_FETCHING_SUCCESS';
export const BRIEFINGS_MORE_FETCHING_FAILURE = 'BRIEFINGS_MORE_FETCHING_FAILURE';
export const BRIEFINGS_RESET = 'BRIEFINGS_RESET';

// FOLLOWED BRIEFING TYPES
export const BRIEFING_TYPES_FETCHING = 'BRIEFING_TYPES_FETCHING';
export const BRIEFING_TYPES_FETCHING_FAILURE = 'BRIEFING_TYPES_FETCHING_FAILURE';
export const BRIEFING_TYPES_FETCHING_SUCCESS = 'BRIEFING_TYPES_FETCHING_SUCCESS';
export const BRIEFING_TYPES_MORE_FETCHING = 'BRIEFING_TYPES_MORE_FETCHING';
export const BRIEFING_TYPES_MORE_FETCHING_FAILURE = 'BRIEFING_TYPES_MORE_FETCHING_FAILURE';
export const BRIEFING_TYPES_MORE_FETCHING_SUCCESS = 'BRIEFING_TYPES_MORE_FETCHING_SUCCESS';

// COMPANIES
export const COMPANIES_LABELS_FETCHING = 'COMPANIES_LABELS_FETCHING';
export const COMPANIES_LABELS_SUCCESS = 'COMPANIES_LABELS_SUCCESS';
export const COMPANIES_LABELS_FAILURE = 'COMPANIES_LABELS_FAILURE';
export const COMPANIES_FETCHING = 'COMPANIES_FETCHING';
export const COMPANIES_FETCHING_SUCCESS = 'COMPANIES_FETCHING_SUCCESS';
export const COMPANIES_FETCHING_FAILURE = 'COMPANIES_FETCHING_FAILURE';
export const COMPANIES_MORE_FETCHING = 'COMPANIES_MORE_FETCHING';
export const COMPANIES_MORE_FETCHING_SUCCESS = 'COMPANIES_MORE_FETCHING_SUCCESS';
export const COMPANIES_MORE_FETCHING_FAILURE = 'COMPANIES_MORE_FETCHING_FAILURE';
export const COMPANIES_RESET = 'COMPANIES_RESET';
export const COMPANIES_SET_COMPANY_HASHTAGS = 'COMPANIES_SET_COMPANY_HASHTAGS';

// COMPANIES
export const COMPANY_FEED_FETCHING = 'COMPANY_FEED_FETCHING';
export const COMPANY_FEED_FETCHING_SUCCESS = 'COMPANY_FEED_FETCHING_SUCCESS';
export const COMPANY_FEED_FETCHING_FAILURE = 'COMPANY_FEED_FETCHING_FAILURE';
export const COMPANY_FEED_MORE_FETCHING = 'COMPANY_FEED_MORE_FETCHING';
export const COMPANY_FEED_MORE_FETCHING_SUCCESS = 'COMPANY_FEED_MORE_FETCHING_SUCCESS';
export const COMPANY_FEED_MORE_FETCHING_FAILURE = 'COMPANY_FEED_MORE_FETCHING_FAILURE';
export const COMPANY_FEED_RESET = 'COMPANY_FEED_RESET';
export const COMPANY_LABELS_FETCHING = 'COMPANY_LABELS_FETCHING';
export const COMPANY_LABELS_SUCCESS = 'COMPANY_LABELS_SUCCESS';
export const COMPANY_LABELS_FAILURE = 'COMPANY_LABELS_FAILURE';

// CONTENTS
export const CONTENT_FETCHING = 'CONTENT_FETCHING';
export const CONTENT_FETCHING_SUCCESS = 'CONTENT_FETCHING_SUCCESS';
export const CONTENT_FETCHING_FAILURE = 'CONTENT_FETCHING_FAILURE';
export const CONTENT_MORE_FETCHING = 'CONTENT_MORE_FETCHING';
export const CONTENT_MORE_FETCHING_SUCCESS = 'CONTENT_MORE_FETCHING_SUCCESS';
export const CONTENT_MORE_FETCHING_FAILURE = 'CONTENT_MORE_FETCHING_FAILURE';
export const CONTENT_RESET = 'CONTENT_RESET';

// ARTICLES PREVIEW
export const ARTICLE_FETCHING = 'ARTICLE_FETCHING';
export const ARTICLE_FETCHING_SUCCESS = 'ARTICLE_FETCHING_SUCCESS';
export const ARTICLE_FETCHING_FAILURE = 'ARTICLE_FETCHING_FAILURE';

// SEARCH
export const SEARCH_FETCHING = 'SEARCH_FETCHING';
export const SEARCH_FETCHING_SUCCESS = 'SEARCH_FETCHING_SUCCESS';
export const SEARCH_FETCHING_FAILURE = 'SEARCH_FETCHING_FAILURE';
export const SEARCH_MORE_FETCHING = 'SEARCH_MORE_FETCHING';
export const SEARCH_MORE_FETCHING_SUCCESS = 'SEARCH_MORE_FETCHING_SUCCESS';
export const SEARCH_MORE_FETCHING_FAILURE = 'SEARCH_MORE_FETCHING_FAILURE';
export const SEARCH_RESET = 'SEARCH_RESET';
export const SEARCH_SEARCH_TEXT = 'SEARCH_SEARCH_TEXT';

// SIGN IN
export const SIGN_IN_FETCHING = 'SIGN_IN_FETCHING';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ANONYMOUS_SUCCESS = 'SIGN_IN_ANONYMOUS_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

// HASHTAG MORE VIEW
export const HASHTAG_FOLLOW = 'HASHTAG_FOLLOW';
export const HASHTAG_UNFOLLOW = 'HASHTAG_UNFOLLOW';

export const HASHTAG_NOTIFICATIONS_UPDATING = 'HASHTAG_NOTIFICATIONS_UPDATING';
export const HASHTAG_NOTIFICATIONS_UPDATING_FAILURE = 'HASHTAG_NOTIFICATIONS_UPDATING_FAILURE';
export const HASHTAG_EMAIL_NOTIFICATIONS_ON = 'HASHTAG_EMAIL_NOTIFICATIONS_ON';
export const HASHTAG_EMAIL_NOTIFICATIONS_OFF = 'HASHTAG_EMAIL_NOTIFICATIONS_OFF';
export const HASHTAG_PUSH_NOTIFICATIONS_ON = 'HASHTAG_PUSH_NOTIFICATIONS_ON';
export const HASHTAG_PUSH_NOTIFICATIONS_OFF = 'HASHTAG_PUSH_NOTIFICATIONS_OFF';

export const HASHTAG_FETCHING = 'HASHTAG_FETCHING';
export const HASHTAG_FETCHING_SUCCESS = 'HASHTAG_FETCHING_SUCCESS';
export const HASHTAG_FETCHING_FAILURE = 'HASHTAG_FETCHING_FAILURE';

// HASHTAG SETTINGS (NOTIFICATIONS TAB)
export const SETTINGS_NOTIFICATIONS_FETCHING = 'SETTINGS_NOTIFICATIONS_FETCHING';
export const SETTINGS_NOTIFICATIONS_FETCHING_SUCCESS = 'SETTINGS_NOTIFICATIONS_FETCHING_SUCCESS';
export const SETTINGS_NOTIFICATIONS_FETCHING_FAILURE = 'SETTINGS_NOTIFICATIONS_FETCHING_FAILURE';

// SETTINGS ACCOUNT TAB
export const SETTINGS_ACCOUNT_USER_FETCHING = 'SETTINGS_ACCOUNT_USER_FETCHING';
export const SETTINGS_ACCOUNT_USER_FETCHING_SUCCESS = 'SETTINGS_ACCOUNT_USER_FETCHING_SUCCESS';
export const SETTINGS_ACCOUNT_USER_FETCHING_FAILURE = 'SETTINGS_ACCOUNT_USER_FETCHING_FAILURE';
export const SETTINGS_ACCOUNT_USER_UPDATING = 'SETTINGS_ACCOUNT_USER_UPDATING';
export const SETTINGS_ACCOUNT_USER_UPDATING_SUCCESS = 'SETTINGS_ACCOUNT_USER_UPDATING_SUCCESS';
export const SETTINGS_ACCOUNT_USER_UPDATING_FAILURE = 'SETTINGS_ACCOUNT_USER_UPDATING_FAILURE';
export const SETTINGS_ACCOUNT_EMAIL_SENT_MODAL_SHOW = 'SETTINGS_ACCOUNT_EMAIL_SENT_MODAL_SHOW';
export const SETTINGS_ACCOUNT_EMAIL_SENT_MODAL_HIDE = 'SETTINGS_ACCOUNT_EMAIL_SENT_MODAL_HIDE';

// UNSUBSCRIBE FROM EMAILS
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const UNSUBSCRIBE_FAILURE = 'UNSUBSCRIBE_FAILURE';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';

// NEWSPAPERS (SOURCES)
export const NEWSPAPERS = 'NEWSPAPERS';
export const NEWSPAPERS_FAILURE = 'NEWSPAPERS_FAILURE';
export const NEWSPAPERS_SUCCESS = 'NEWSPAPERS_SUCCESS';

// SUBSCRIPTIONS
export const SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_REQUEST = 'SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_REQUEST';
export const SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_SUCCESS = 'SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_SUCCESS';
export const SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_FAILURE = 'SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_FAILURE';
export const SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_REQUEST = 'SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_REQUEST';
export const SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SUCCESS';
export const SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_FAILURE = 'SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_FAILURE';
export const SUBSCRIPTIONS_CREATE_SUBSCRIPTION_REQUEST = 'SUBSCRIPTIONS_CREATE_SUBSCRIPTION_REQUEST';
export const SUBSCRIPTIONS_CREATE_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS_CREATE_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_CREATE_SUBSCRIPTION_FAILURE = 'SUBSCRIPTIONS_CREATE_SUBSCRIPTION_FAILURE';
export const SUBSCRIPTIONS_SELECT_SUBSCRIPTION_PLAN = 'SUBSCRIPTIONS_SELECT_SUBSCRIPTION_PLAN';
export const SUBSCRIPTIONS_ADD_CARD_REQUEST = 'SUBSCRIPTIONS_ADD_CARD_REQUEST';
export const SUBSCRIPTIONS_ADD_CARD_SUCCESS = 'SUBSCRIPTIONS_ADD_CARD_SUCCESS';
export const SUBSCRIPTIONS_ADD_CARD_FAILURE = 'SUBSCRIPTIONS_ADD_CARD_FAILURE';
export const SUBSCRIPTIONS_DELETE_CARD_REQUEST = 'SUBSCRIPTIONS_DELETE_CARD_REQUEST';
export const SUBSCRIPTIONS_DELETE_CARD_SUCCESS = 'SUBSCRIPTIONS_DELETE_CARD_SUCCESS';
export const SUBSCRIPTIONS_DELETE_CARD_FAILURE = 'SUBSCRIPTIONS_DELETE_CARD_FAILURE';
export const SUBSCRIPTIONS_FETCH_CARDS_REQUEST = 'SUBSCRIPTIONS_FETCH_CARDS_REQUEST';
export const SUBSCRIPTIONS_FETCH_CARDS_SUCCESS = 'SUBSCRIPTIONS_FETCH_CARDS_SUCCESS';
export const SUBSCRIPTIONS_FETCH_CARDS_FAILURE = 'SUBSCRIPTIONS_FETCH_CARDS_FAILURE';
export const SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_REQUEST = 'SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_REQUEST';
export const SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_SUCCESS = 'SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_SUCCESS';
export const SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_FAILURE = 'SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_FAILURE';
export const SUBSCRIPTIONS_UNSUBSCRIBE_REQUEST = 'SUBSCRIPTIONS_UNSUBSCRIBE_REQUEST';
export const SUBSCRIPTIONS_UNSUBSCRIBE_SUCCESS = 'SUBSCRIPTIONS_UNSUBSCRIBE_SUCCESS';
export const SUBSCRIPTIONS_UNSUBSCRIBE_FAILURE = 'SUBSCRIPTIONS_UNSUBSCRIBE_FAILURE';

// ACCEPT TRIAL
export const ACCEPT_TRIAL_REQUEST = 'ACCEPT_TRIAL_REQUEST';
export const ACCEPT_TRIAL_SUCCESS = 'ACCEPT_TRIAL_SUCCESS';
export const ACCEPT_TRIAL_FAILURE = 'ACCEPT_TRIAL_FAILURE';

export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST';
