import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import Error from './Error';
import theme from '../../../theme';

export const styles = {
  margin: {
    marginBottom: 20,
  },
  cssLabel: {
    '&$cssFocused': {
      color: theme.color.fontDefaultColor,
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.color.formField,
    },
  },
  cssUnderlineError: {
    '&:after': {
      borderBottomColor: theme.color.formFieldError,
    },
  },
  validTextColor: {
    '&:focus': {
      color: theme.color.formField,
    },
  },
  invalidTextColor: {
    '&:focus': {
      color: theme.color.formFieldError,
    },
  },
};

const MaterializedInput = ({
  classes, label, inputFormProps, type, meta, multiline, rows, disabled, autofocus
}) => (
  <FormControl className={classes.margin} fullWidth>
    <InputLabel
      formlabelclasses={{
        root: classes.cssLabel,
        focused: classes.cssFocused,
      }}
    >
      {label}
    </InputLabel>
    <Input
      rows={rows}
      multiline={multiline}
      type={type}
      {...inputFormProps}
      classes={{
        underline: meta.touched && meta.error ? classes.cssUnderlineError : classes.cssUnderline,
        input: meta.touched && meta.error ? classes.invalidTextColor : classes.validTextColor,
      }}
      disabled={disabled}
      autoFocus={autofocus}
    />
    {(meta.error || meta.submitError) && meta.touched
      && <Error meta={meta} />}
  </FormControl>
);

MaterializedInput.defaultProps = {
  multiline: false,
  rows: 4,
  type: 'text',
  disabled: false,
  autofocus: false,
};
MaterializedInput.propTypes = {
  label: PropTypes.string.isRequired,
  autofocus: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

class MaterializedPasswordInput extends Component {
  state = {
    showPassword: false,
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    const {
      classes,
      label,
      inputFormProps,
      meta,
      autofocus,
    } = this.props;

    return (
      <FormControl className={classes.margin} fullWidth>
        <InputLabel
          formlabelclasses={{
            root: classes.cssLabel,
            focused: classes.cssFocused,
          }}
        >
          {label}
        </InputLabel>
        <Input
          {...inputFormProps}
          classes={{
            underline: meta.touched && meta.error ? classes.cssUnderlineError : classes.cssUnderline,
            input: meta.touched && meta.error ? classes.invalidTextColor : classes.validTextColor,
          }}
          type={this.state.showPassword ? 'text' : 'password'}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                onClick={this.handleClickShowPassword}
                tabIndex={-1}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}
          autoFocus={autofocus}
        />
        {(meta.error || meta.submitError) && meta.touched
          && <Error meta={meta} />}
      </FormControl>
    );
  }
}

export const PrimaryInput = withStyles(styles)(MaterializedInput);
export const PasswordInput = withStyles(styles)(MaterializedPasswordInput);
