import Rollbar from 'rollbar';

const rollbar = new Rollbar();

const { REACT_APP_ROLLBAR_CLIENT_TOKEN, NODE_ENV } = process.env;
const analystEmail = localStorage.getItem('email') || 'anonymous';

if (NODE_ENV === 'development') {
  rollbar.configure({ enabled: false });
} else {
  const rollbarConfig = {
    accessToken: REACT_APP_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    payload: {
      environment: NODE_ENV,
      person: {
        email: analystEmail
      },
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: buildTimestamp,
          guess_uncaught_frames: true
        }
      }
    }
  };
  rollbar.configure(rollbarConfig);
}

export default rollbar;
