import axios from 'axios';
import { store } from '../store';
import { logout } from '../actions/auth';

const AUTH_TOKEN = localStorage.getItem('token');
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

if (AUTH_TOKEN) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

axiosInstance.interceptors.response
  .use((response) => response, (error) => {
    if (error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error.response.data);
  });

export const setAuthenticationToken = (token) => {
  localStorage.setItem('token', token);
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default axiosInstance;
