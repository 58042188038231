import {
  UNAUTH_NEWS_FETCHING,
  UNAUTH_NEWS_FETCHING_SUCCESS,
  UNAUTH_NEWS_FETCHING_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  clientCoverages: [],
  summaries: [],
  isFetching: false,
  error: null
};

const fetchingData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingSuccess = (state, {
  payload: {
    summaries, clientCoverages, newSummaries, newCoverages
  }
}) => ({
  ...state,
  isFetching: false,
  summaries: [...summaries],
  clientCoverages: [...clientCoverages],
  newSummaries,
  newCoverages
});

const fetchingFailure = (state, { error: { error } }) => ({
  ...state,
  isFetching: false,
  error
});

export default (state = initialState, action) => {
  switch (action.type) {
    case UNAUTH_NEWS_FETCHING:
      return fetchingData(state, action);

    case UNAUTH_NEWS_FETCHING_SUCCESS:
      return fetchingSuccess(state, action);

    case UNAUTH_NEWS_FETCHING_FAILURE:
      return fetchingFailure(state, action);

    default:
      return state;
  }
};
