import moment from 'moment';
import {
  DASHBOARD_FETCH_SELECTS_DATA,
  DASHBOARD_FETCH_SELECTS_DATA_SUCCESS,
  DASHBOARD_FETCH_CHART_DATA,
  DASHBOARD_FETCH_CHART_DATA_SUCCESS,
  DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA,
  DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA_SUCCESS,
  DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA,
  DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA_SUCCESS,
  DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA,
  DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA_SUCCESS,
  DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA,
  DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA_SUCCESS,
  DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA,
  DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_SUCCESS,
  DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA,
  DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA_SUCCESS,
  DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA,
  DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_SUCCESS,
  DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA,
  DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA_SUCCESS,
  UPDATE_PUBLICATION_BY_COMPANIES_FILTER,
  UPDATE_TOP_SOURCES_FILTER,
  UPDATE_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_FILTER,
  UPDATE_REQUEST_DETAILED_REPORT_FILTER,
  DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA,
  DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA_SUCCESS,
  UPDATE_SENTIMENT_FOR_COMPANIES_FILTER,
} from '../actions/actionTypes';

const initialState = {
  companies: [],
  sectors: [],
  newspapers: [],
  loadingDropdownsData: true,
  loadingBarChartData: true,
  loadingPublicationsByCompaniesBarChartData: false,
  loadingPublicationsByNewspapersBarChartData: false,
  loadingPublicationsByNewspapersForCompaniesBarChartData: false,
  loadingPublicationsByNewspapersForCompaniesBarPieData: false,
  loadingPublicationsBySentimentsForCompaniesBarChartData: false,
  loadingSentimentForCompaniesChartData: false,
  loadingPublicationsBySentimentsForNewspapersBarChartData: false,
  loadingJournalistsBySentimentsForCompaniesBarChartData: false,
  loadingTopSourcesChartData: false,
  totals: {},
  barChartData: [],
  publicationsByCompaniesBarChartData: [],
  publicationsByNewspapersBarChartData: [],
  publicationsByNewspapersForCompaniesBarChartData: [],
  publicationsByNewspapersForCompaniesBarPieData: [],
  publicationsBySentimentsForCompaniesBarChartData: [],
  publicationsBySentimentsForNewspapersBarChartData: [],
  journalistsBySentimentsForCompaniesBarChartData: [],
  publicationsByCompaniesCompanyNames: [],
  publicationsByNewspapersNewspaperNames: [],
  publicationsByNewspapersForCompaniesCompanyNames: [],
  publicationsByNewspapersForCompaniesPieCompanyNames: [],
  topSourcesChartData: [],
  sentimentForCompaniesBarChartData: [],
  publicationsByCompaniesFilters: {
    aggregateResults: false,
    startDate: moment().subtract(6, 'months').toDate(),
    endDate: moment().toDate(),
    selectedNewspapers: [],
    defaultCompanies: [],
    selectedCompanies: [],
    selectedInterval: 'month',
    chartType: 'bar',
    percentChartData: [],
    onlyStandard: true,
    companyColors: true,
  },
  topSourcesFilters: {
    startDate: moment().subtract(6, 'months').toDate(),
    endDate: moment().toDate(),
    defaultCompanies: [],
    selectedCompanies: [],
    showUpdateButton: false,
    showAlert: false,
    onlyStandard: true,
    showSentimentTopSources: true,
    showSentimentTopJournalists: true,
  },
  journalistsBySentimentsForCompaniesFilters: {
    startDate: moment().subtract(6, 'months').toDate(),
    endDate: moment().toDate(),
    defaultCompanies: [],
    selectedCompanies: [],
    showUpdateButton: false,
    showAlert: false,
    onlyStandard: true,
    showSentimentTopJournalists: true,
  },
  requestDetailedReportFilters: {
    startDate: moment().subtract(6, 'months').toDate(),
    endDate: moment().toDate(),
    defaultCompanies: [],
    selectedCompanies: [],
    showAlert: false,
    onlyStandard: true
  },
  sentimentForCompaniesFilters: {
    startDate: moment().subtract(6, 'months').toDate(),
    endDate: moment().toDate(),
    defaultCompanies: [],
    selectedCompanies: [],
    onlyStandard: true
  },
};

const fetchingDropdownsData = (state) => ({
  ...state,
  loadingDropdownsData: true
});

const fetchingDropdownsDataSuccess = (state, { payload }) => {
  // eslint-disable-next-line no-unused-vars
  const newspapers = payload.newspapers.map((item) => ({ label: item.title, value: item.id }));
  return ({
    ...state,
    // companies: payload.companies,
    // sectors: payload.sectors,
    // newspapers,
    ...payload,
    loadingDropdownsData: false
  });
};

const fetchingChartData = (state) => ({
  ...state,
  loadingBarChartData: true
});

const fetchingPublicationsByCompaniesChartData = (state) => ({
  ...state,
  loadingPublicationsByCompaniesBarChartData: true
});

const fetchingPublicationsByNewspapersChartData = (state) => ({
  ...state,
  loadingPublicationsByNewspapersBarChartData: true
});

const fetchingPublicationsByNewspapersForCompaniesChartData = (state) => ({
  ...state,
  loadingPublicationsByNewspapersForCompaniesBarChartData: true
});

const fetchingPublicationsByNewspapersForCompaniesPieData = (state) => ({
  ...state,
  loadingPublicationsByNewspapersForCompaniesBarPieData: true
});

const fetchingPublicationsBySentimentsForCompaniesChartData = (state) => ({
  ...state,
  loadingPublicationsBySentimentsForCompaniesBarChartData: true
});

const fetchingSentimentForCompaniesChartData = (state) => ({
  ...state,
  loadingSentimentForCompaniesChartData: true
});

const fetchingPublicationsBySentimentsForNewspapersChartData = (state) => ({
  ...state,
  loadingPublicationsBySentimentsForNewspapersBarChartData: true
});

const fetchingJournalistsBySentimentsForCompaniesChartData = (state) => ({
  ...state,
  loadingJournalistsBySentimentsForCompaniesBarChartData: true
});

const fetchingTopSourcesChartData = (state) => ({
  ...state,
  loadingTopSourcesChartData: true
});


const fetchingPublicationsByCompaniesChartDataSuccess = (state, { payload }) => ({
  ...state,
  loadingPublicationsByCompaniesBarChartData: false,
  publicationsByCompaniesBarChartData: payload.data,
  publicationsByCompaniesCompanyNames: payload.companyNames
});

const fetchingPublicationsByNewspapersChartDataSuccess = (state, { payload }) => ({
  ...state,
  loadingPublicationsByNewspapersBarChartData: false,
  publicationsByNewspapersBarChartData: payload.data,
  publicationsByNewspapersNewspaperNames: payload.newspaperNames
});

const fetchingPublicationsByNewspapersForCompaniesChartDataSuccess = (state, { payload }) => ({
  ...state,
  loadingPublicationsByNewspapersForCompaniesBarChartData: false,
  publicationsByNewspapersForCompaniesBarChartData: payload.data,
  publicationsByNewspapersForCompaniesCompanyNames: payload.companyNames
});

const fetchingPublicationsByNewspapersForCompaniesPieDataSuccess = (state, { payload }) => ({
  ...state,
  loadingPublicationsByNewspapersForCompaniesBarPieData: false,
  publicationsByNewspapersForCompaniesBarPieData: payload.data,
  publicationsByNewspapersForCompaniesPieCompanyNames: payload.companyNames
});

const fetchingPublicationsBySentimentsForCompaniesChartDataSuccess = (state, { payload }) => ({
  ...state,
  loadingPublicationsBySentimentsForCompaniesBarChartData: false,
  publicationsBySentimentsForCompaniesBarChartData: payload.data,
});

const fetchingSentimentForCompaniesChartDataSuccess = (state, { payload }) => ({
  ...state,
  loadingSentimentForCompaniesChartData: false,
  sentimentForCompaniesBarChartData: payload.data,
});

const fetchingPublicationsBySentimentsForNewspapersChartDataSuccess = (state, { payload }) => ({
  ...state,
  loadingPublicationsBySentimentsForNewspapersBarChartData: false,
  publicationsBySentimentsForNewspapersBarChartData: payload.data,
});

const fetchingJournalistsBySentimentsForCompaniesChartDataSuccess = (state, { payload }) => ({
  ...state,
  loadingJournalistsBySentimentsForCompaniesBarChartData: false,
  journalistsBySentimentsForCompaniesBarChartData: payload.data,
});

const fetchingChartDataSuccess = (state, { payload: { totals, counters } }) => ({
  ...state,
  loadingBarChartData: false,
  totals,
  barChartData: counters,
});

const fetchingTopSourcesChartDataSuccess = (state, { payload }) => ({
  ...state,
  loadingTopSourcesChartData: false,
  topSourcesChartData: payload.data,
});

const updatePublicationsByCompaniesFilter = (state, { payload }) => ({
  ...state,
  publicationsByCompaniesFilters: {
    ...state.publicationsByCompaniesFilters,
    ...payload,
  }
});

const updateTopSourcesFilter = (state, { payload }) => ({
  ...state,
  topSourcesFilters: {
    ...state.topSourcesFilters,
    ...payload,
  }
});

const updateJournalistsBySentimentsForCompaniesFilter = (state, { payload }) => ({
  ...state,
  journalistsBySentimentsForCompaniesFilters: {
    ...state.journalistsBySentimentsForCompaniesFilters,
    ...payload,
  }
});

const updateRequestDetailedReportFilter = (state, { payload }) => ({
  ...state,
  requestDetailedReportFilters: {
    ...state.requestDetailedReportFilters,
    ...payload,
  }
});

const updateSentimentForCompaniesFilter = (state, { payload }) => ({
  ...state,
  sentimentForCompaniesFilters: {
    ...state.sentimentForCompaniesFilters,
    ...payload,
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_FETCH_SELECTS_DATA:
      return fetchingDropdownsData(state, action);

    case DASHBOARD_FETCH_CHART_DATA:
      return fetchingChartData(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA:
      return fetchingPublicationsByCompaniesChartData(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA:
      return fetchingPublicationsByNewspapersChartData(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA:
      return fetchingPublicationsByNewspapersForCompaniesChartData(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA:
      return fetchingPublicationsByNewspapersForCompaniesPieData(state, action);

    case DASHBOARD_FETCH_CHART_DATA_SUCCESS:
      return fetchingChartDataSuccess(state, action);

    case DASHBOARD_FETCH_SELECTS_DATA_SUCCESS:
      return fetchingDropdownsDataSuccess(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_COMPANIES_CHART_DATA_SUCCESS:
      return fetchingPublicationsByCompaniesChartDataSuccess(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_CHART_DATA_SUCCESS:
      return fetchingPublicationsByNewspapersChartDataSuccess(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_CHART_DATA_SUCCESS:
      return fetchingPublicationsByNewspapersForCompaniesChartDataSuccess(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_NEWSPAPERS_FOR_COMPANIES_PIE_DATA_SUCCESS:
      return fetchingPublicationsByNewspapersForCompaniesPieDataSuccess(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA:
      return fetchingPublicationsBySentimentsForCompaniesChartData(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_SUCCESS:
      return fetchingPublicationsBySentimentsForCompaniesChartDataSuccess(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA:
      return fetchingPublicationsBySentimentsForNewspapersChartData(state, action);

    case DASHBOARD_FETCH_PUBLICATIONS_BY_SENTIMENTS_FOR_NEWSPAPERS_CHART_DATA_SUCCESS:
      return fetchingPublicationsBySentimentsForNewspapersChartDataSuccess(state, action);

    case DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA:
      return fetchingJournalistsBySentimentsForCompaniesChartData(state, action);

    case DASHBOARD_FETCH_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_CHART_DATA_SUCCESS:
      return fetchingJournalistsBySentimentsForCompaniesChartDataSuccess(state, action);

    case DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA:
      return fetchingTopSourcesChartData(state, action);

    case DASHBOARD_FETCH_TOP_SOURCES_CHART_DATA_SUCCESS:
      return fetchingTopSourcesChartDataSuccess(state, action);

    case UPDATE_PUBLICATION_BY_COMPANIES_FILTER:
      return updatePublicationsByCompaniesFilter(state, action);

    case UPDATE_TOP_SOURCES_FILTER:
      return updateTopSourcesFilter(state, action);

    case UPDATE_JOURNALISTS_BY_SENTIMENTS_FOR_COMPANIES_FILTER:
      return updateJournalistsBySentimentsForCompaniesFilter(state, action);

    case UPDATE_REQUEST_DETAILED_REPORT_FILTER:
      return updateRequestDetailedReportFilter(state, action);

    case DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA:
      return fetchingSentimentForCompaniesChartData(state, action);

    case DASHBOARD_FETCH_SENTIMENT_FOR_COMPANIES_CHART_DATA_SUCCESS:
      return fetchingSentimentForCompaniesChartDataSuccess(state, action);

    case UPDATE_SENTIMENT_FOR_COMPANIES_FILTER:
      return updateSentimentForCompaniesFilter(state, action);

    default:
      return state;
  }
};
