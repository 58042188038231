import { TOGGLE_CONTACT_US_MODAL } from './actionTypes';
import { fetchUserInfo } from './account';
import { fetchSubscriptions } from './subscriptions';
import { initializeGA } from '../googleAnalytics';
import { initializeIntercom } from '../intercom';
import statraf from '../config/statraf';

const STATRAF_ENDPOINT = 'collect.json';
const { NODE_ENV } = process.env;

export const initializeApp = () => async (dispatch) => {
  if (localStorage.getItem('token')) {
    await dispatch(fetchUserInfo());
    dispatch(fetchSubscriptions());
  }
};

export const initializeThirdParty = () => async (dispatch, getState) => {
  if (NODE_ENV !== 'test') {
    const { account: { data: { email, externalId, intercomHash } } } = getState();
    initializeIntercom({ user_id: externalId, email, intercomHash });
    initializeGA(externalId);
  }
};

export const toggleContactUsModal = () => ({
  type: TOGGLE_CONTACT_US_MODAL,
});

export const statrafLocationChangeEvent = (history) => (dispatch, getState) => {
  if (NODE_ENV === 'development' || NODE_ENV === 'test') {
    return;
  }
  const historyListener = (location) => {
    const { account: { data: { externalId, email } } } = getState();
    statraf.post(STATRAF_ENDPOINT, {
      user_id: externalId,
      user_email: email,
      location: window.location.href,
      path: location.pathname
    });
  };
  history.listen(historyListener);
  historyListener(history.location);
};

export const statrafSendEvent = (content) => (dispatch, getState) => {
  if (NODE_ENV === 'development') {
    return;
  }
  const {
    account: {
      data: { externalId, email }
    },
    router: {
      location: { pathname }
    }
  } = getState();
  statraf.post(STATRAF_ENDPOINT, {
    user_id: externalId,
    user_email: email,
    path: pathname,
    location: window.location.href,
    type: 'share',
    ...content
  });
};
