import {
  COMPANIES_FETCHING,
  COMPANIES_FETCHING_FAILURE,
  COMPANIES_FETCHING_SUCCESS,
  COMPANIES_LABELS_FAILURE,
  COMPANIES_LABELS_FETCHING,
  COMPANIES_LABELS_SUCCESS,
  COMPANIES_MORE_FETCHING,
  COMPANIES_MORE_FETCHING_FAILURE,
  COMPANIES_MORE_FETCHING_SUCCESS,
  COMPANIES_RESET,
  COMPANIES_SET_COMPANY_HASHTAGS,
  HASHTAG_FOLLOW,
  HASHTAG_UNFOLLOW,
  RESET_STORED_DATA
} from '../actions/actionTypes';
import { mergeSections } from './myNews';

const initialState = {
  labels: [],
  tabs: {},
  isFetchingLabels: false,
  labelsFetched: false,
  error: null,
  hashtags: [],
};

const fetchingLabels = (state) => ({
  ...state,
  isFetchingLabels: true
});

const fetchingLabelsSuccess = (state, { payload: { items } }) => ({
  ...state,
  isFetchingLabels: false,
  labelsFetched: true,
  labels: [...(items || [])],
});

const fetchingLabelsFailure = (state) => ({
  ...state,
  isFetchingLabels: false,
  labelsFetched: true,
  error: true
});

const fetchingData = (state, { payload: { groupId } }) => ({
  ...state,
  tabs: {
    ...state.tabs,
    [groupId]: {
      ...state.tabs[groupId],
      isFetching: true
    }
  }
});

const fetchingSuccess = (
  state,
  {
    payload: {
      sections, links, counts, groupId
    }
  }
) => ({
  ...state,
  tabs: {
    ...state.tabs,
    [groupId]: {
      isFetching: false,
      firstRequest: false,
      sections: sections.map((section) => ({
        ...section,
        disabled: true,
        counter: counts ? `${counts.followed}/${counts.all}` : undefined
      })),
      links
    }
  }
});

const fetchingFailure = (state, { error: { error, groupId } }) => ({
  ...state,
  tabs: {
    ...state.tabs,
    [groupId]: {
      isFetching: false,
      error
    }
  }
});

const fetchingMoreData = (state, { payload: { groupId } }) => ({
  ...state,
  tabs: {
    ...state.tabs,
    [groupId]: {
      ...state.tabs[groupId],
      isFetching: true
    }
  }
});

const fetchingMoreSuccess = (
  state,
  { payload: { sections, links, groupId } }
) => {
  const merged = mergeSections(state.tabs[groupId].sections, sections, links);
  if (merged) {
    return {
      ...state,
      tabs: {
        ...state.tabs,
        [groupId]: {
          ...state.tabs[groupId],
          ...merged,
          sections: merged.sections.map((section) => ({
            ...section,
            disabled: true
          }))
        }
      }
    };
  }

  return {
    ...state,
    tabs: {
      ...state.tabs,
      [groupId]: {
        ...state.tabs[groupId],
        sections: [...state.tabs[groupId].sections, ...sections].map(
          (section) => ({
            ...section,
            disabled: true
          })
        ),
        isFetching: false,
        links
      }
    }
  };
};

const fetchingMoreFailure = (state, { payload: { groupId } }) => ({
  ...state,
  tabs: {
    ...state.tabs,
    [groupId]: {
      isFetching: false,
      error: true
    }
  }
});

const toggleFollow = (state, item, follow) => {
  const tabs = Object.keys(state.tabs).reduce((acc, key) => {
    const tab = state.tabs[key];
    const sections = tab.sections.map((section) => {
      const data = section.data.map((el) => {
        if (el.id === item.id) {
          return {
            ...el,
            follow
          };
        }
        return el;
      });
      return {
        ...section,
        data
      };
    });
    acc[key] = {
      ...tab,
      sections
    };
    return acc;
  }, {});

  return {
    ...state,
    hashtags: state.hashtags.map((hashtag) => {
      if (hashtag.id === item.id) {
        return {
          ...hashtag,
          follow,
        };
      }

      return hashtag;
    }),
    tabs
  };
};

const follow = (state, { payload: { item } }) => toggleFollow(state, item, true);
const unfollow = (state, { payload: { item } }) => toggleFollow(state, item, false);

const setCompaniesHashtags = (state, { hashtags }) => ({
  ...state,
  hashtags,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case HASHTAG_UNFOLLOW:
      return unfollow(state, action);

    case HASHTAG_FOLLOW:
      return follow(state, action);

    case COMPANIES_LABELS_FETCHING:
      return fetchingLabels(state, action);

    case COMPANIES_LABELS_SUCCESS:
      return fetchingLabelsSuccess(state, action);

    case COMPANIES_LABELS_FAILURE:
      return fetchingLabelsFailure(state, action);

    case COMPANIES_FETCHING:
      return fetchingData(state, action);

    case COMPANIES_FETCHING_SUCCESS:
      return fetchingSuccess(state, action);

    case COMPANIES_FETCHING_FAILURE:
      return fetchingFailure(state, action);

    case COMPANIES_MORE_FETCHING:
      return fetchingMoreData(state, action);

    case COMPANIES_MORE_FETCHING_SUCCESS:
      return fetchingMoreSuccess(state, action);

    case COMPANIES_MORE_FETCHING_FAILURE:
      return fetchingMoreFailure(state, action);

    case RESET_STORED_DATA:
    case COMPANIES_RESET:
      return initialState;

    case COMPANIES_SET_COMPANY_HASHTAGS:
      return setCompaniesHashtags(state, action);

    default:
      return state;
  }
};
