import styled from 'styled-components';

// add prefered props
const Svg = styled.img`
  ${(props) => (props.height ? `height: ${props.height}px` : '')};
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight}px` : '')};
  ${(props) => (props.width ? `width: ${props.width}px` : '')};
  ${(props) => (props.maxWidth ? `max-width: ${typeof props.maxWidth === 'string' ? props.maxWidth : `${props.maxWidth}px`}` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}px` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}px` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}px` : '')};
  ${(props) => (props.mt ? `margin-top: ${props.mt}px` : '')};
  ${(props) => (props.pr ? `padding-right: ${props.pr}px` : '')};
  ${(props) => (props.pl ? `padding-left: ${props.pl}px` : '')};
  ${(props) => (props.pb ? `padding-bottom: ${props.pb}px` : '')};
  ${(props) => (props.pt ? `padding-top: ${props.pt}px` : '')};
  ${(props) => (props.clickable ? 'cursor: pointer;' : '')};
`;

export default Svg;
