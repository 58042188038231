import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import LoadingIndicator from './components/common/LoadingIndicator';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import { isUserAuthenticated, redirectAuthenticatedUser } from './auth';
import { GlobalStyle } from './global-styles';
import { APP_ROUTES } from './constants/routes';
import { initializeApp, statrafLocationChangeEvent } from './actions/app';
import { renderPdf } from './utils';

import pdfPrivacy from './static/pdf/Briefcase_News_Privacy_and_cookies_policy_10_March_2020.pdf';
import pdfTerms from './static/pdf/Briefcase_News_Consumer_Terms_and_Conditions_updated_21_May_2018.pdf';
import pdfTermsB2B from './static/pdf/Briefcase_News_Business_Terms_and_Conditions_23_May_2019.pdf';
import pdfTermsB2BWithDoubleSlash from './static/pdf/Briefcase_News_Business_Terms_and_Conditions_04_March_2019.pdf';
import pdfAcceptable from './static/pdf/Briefcase_News_Acceptable_use_policy_10_March_2020.pdf';
import pdfFTSE350RankingsReport2019 from './static/pdf/FTSE_350_Rankings_Report_Dec_2019.pdf';
import pdfFTSERankingsReport2020Q1 from './static/pdf/FTSE_Rankings_Report_Q1_2020.pdf';
import pdfFTSERankingsReport2020Q2 from './static/pdf/FTSE_Rankings_Report_Q2_2020.pdf';
import pdfPrivateEquity20221007 from './static/pdf/Private_Equity_20221007.pdf';
import pdfPrivateEquity20221103 from './static/pdf/Private_Equity_20221103.pdf';
import pdfPrivateEquity20221202 from './static/pdf/Private_Equity_20221202.pdf';
import pdfPrivateEquity20230105 from './static/pdf/Private_Equity_20230105.pdf';
import pdfPrivateEquity20230207 from './static/pdf/Private_Equity_20230207.pdf';
import pdfPrivateEquity20230308 from './static/pdf/Private_Equity_20230308.pdf';
import pdfPrivateEquity20230411 from './static/pdf/Private_Equity_20230411.pdf';
import pdfPrivateEquity20230510 from './static/pdf/Private_Equity_20230510.pdf';
import pdfPrivateEquity20230531 from './static/pdf/Private_Equity_20230531.pdf';

import briefcaseLogo from './static/logos/briefcase.png';
import CookieBanner from './components/CookieBanner';

const async = (loader) => Loadable({
  loader,
  loading: (props) => {
    if (props.error) {
      window.location.reload();
    }
    return <LoadingIndicator />;
  }
});

export const AsyncNews = async(() => import('./containers/NewsPage'));
export const AsyncWhyBriefcasePage = async(() => import('./containers/WhyBriefcasePage'));
export const AsyncHowWeDoItPage = async(() => import('./containers/HowWeDoItPage'));
export const AsyncWhatWeDoPage = async(() => import('./containers/WhatWeDoPage'));
export const AsyncSourcesPage = async(() => import('./containers/SourcesPage'));
export const AsyncTeamPage = async(() => import('./containers/TeamPage'));
export const AsyncCareersPage = async(() => import('./containers/CareersPage'));
export const AsyncNotFoundPage = async(() => import('./containers/NotFoundPage'));
export const AsyncSomethingWentWrong = async(() => import('./containers/SomethingWentWrong'));

export const AsyncOurClientsPage = async(() => import('./containers/OurClientsPage'));
export const AsyncCompareOurProductsPage = async(() => import('./containers/CompareOurProductsPage'));
export const AsyncRequestDemoPage = async(() => import('./containers/RequestDemoPage'));
export const AsyncRequestDemo350ClubPage = async(() => import('./containers/RequestDemo350ClubPage'));

export const AsyncBriefingsPage = async(() => import('./containers/BriefingsPage'));
export const AsyncCompanyPage = async(() => import('./containers/CompanyPage'));
export const AsyncAdvisoryPage = async(() => import('./containers/AdvisoryPage'));
export const AsyncBespokePage = async(() => import('./containers/BespokePage'));
export const AsyncJobApplicationPage = async(() => import('./containers/JobApplicationPage.js'));
export const AsyncLoginPage = async(() => import('./containers/LoginPage.js'));
export const AsyncSecureLinkPage = async(() => import('./containers/SecureLinkPage'));
export const AsyncSecureLinkSuccessPage = async(() => import('./containers/SecureLinkSuccessPage'));
export const AsyncSetPasswordPage = async(() => import('./containers/SetPasswordPage'));
export const AsyncResetPasswordPage = async(() => import('./containers/ResetPasswordPage'));
export const AsyncMagicLinkPage = async(() => import('./containers/MagicLinkPage'));
export const AsyncStartTrialPage = async(() => import('./containers/subscriptions/StartTrialPage'));
export const AsyncStripePaymentPage = async(() => import('./containers/subscriptions/StripePaymentPage.js'));
export const AsyncSubscriptionSuccessPage = async(() => import('./containers/subscriptions/SubscriptionSuccessPage'));
export const AsyncCancelSubscriptionPage = async(() => import('./containers/subscriptions/CancelSubscriptionPage'));
export const AsyncSubscriptionFailurePage = async(() => import('./containers/subscriptions/SubscriptionFailurePage'));
export const AsyncUpdateCardPage = async(() => import('./containers/subscriptions/UpdateCardPage'));
export const AsyncDeleteCardPage = async(() => import('./containers/subscriptions/DeleteCardPage'));
export const AsyncAcceptTrialPage = async(() => import('./containers/AcceptTrialPage'));
export const AsyncPaymentMethodPage = async(() => import('./containers/subscriptions/PaymentMethodPage'));
export const AsyncContactPage = async(() => import('./containers/ContactPage'));
// private routes
export const AsyncDashboardPage = async(() => import('./containers/auth/DashboardPage'));
export const AsyncCompaniesTrialPage = async(() => import('./containers/auth/CompaniesTrialPage'));
export const AsyncCompaniesPage = async(() => import('./containers/auth/CompaniesPage.js'));
export const AsyncCompanyHashtagsPage = async(() => import('./containers/auth/CompanyHashtagsPage'));
export const AsyncMyNewsPage = async(() => import('./containers/auth/MyNewsPage.js'));
export const AsyncMyBriefingsPage = async(() => import('./containers/auth/MyBriefingsPage.js'));
export const AsyncMyBriefingsFollowedPage = async(() => import('./containers/auth/MyBriefingsFollowedPage.js'));
export const AsyncMyBriefingsSettingsPage = async(() => import('./containers/auth/MyBriefingsSettingsPage.js'));
export const AsyncCompanyDetailsPage = async(() => import('./containers/auth/CompanyDetailsPage.js'));
export const AsyncCompanySettingsPage = async(() => import('./containers/auth/CompanySettingsPage.js'));
export const AsyncSettingsPage = async(() => import('./containers/auth/SettingsPage.js'));
export const AsyncSubscriptionInfoPage = async(() => import('./containers/auth/SubscriptionInfoPage'));
export const AsyncChangePasswordPage = async(() => import('./containers/auth/ChangePasswordPage.js'));
export const AsyncPersonalDataPage = async(() => import('./containers/auth/PersonalDataPage'));
export const AsyncAccountPage = async(() => import('./containers/auth/AccountPage'));
export const AsyncNotificationsPage = async(() => import('./containers/auth/NotificationsPage'));
export const AsyncContactUsPage = async(() => import('./containers/auth/ContactUsPage'));
export const AsyncSearchPage = async(() => import('./containers/SearchPage'));
export const AsyncArticlePage = async(() => import('./containers/auth/ArticlePage.js'));
export const AsyncSingleCoveragePage = async(() => import('./containers/auth/SingleCoveragePage.js'));
export const AsyncResetPasswordEmailSentPage = async(() => import('./containers/ResetPasswordEmailSentPage'));

// legacy
const AsyncUnsubscribePage = async(() => import('./containers/UnsubscribePage'));

class App extends Component {
  async componentDidMount() {
    const { history } = this.props;
    document.body.removeAttribute('class');

    await this.props.initializeApp();
    this.props.statrafLocationChangeEvent(history);
  }

  render() {
    const { history, isAuthenticated } = this.props;
    return (
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
        <CookieBanner />
        <ConnectedRouter history={history}>
          <GlobalStyle />
          <ScrollToTop>
            <NavBar isAuthenticated={isAuthenticated} />
            <Switch>
              <Route exact path={APP_ROUTES.base} component={AsyncWhyBriefcasePage} />
              <Route exact path={APP_ROUTES.somethingWentWrong} component={AsyncSomethingWentWrong} />

              <Route exact path={APP_ROUTES.news} component={AsyncNews} />
              <Route exact path={APP_ROUTES.howWeDoIt} component={AsyncHowWeDoItPage} />
              <Route exact path={APP_ROUTES.whatWeDo} component={AsyncWhatWeDoPage} />
              <Route exact path={APP_ROUTES.team} component={AsyncTeamPage} />
              <Route exact path={APP_ROUTES.careers} component={AsyncCareersPage} />
              <Route exact path={APP_ROUTES.careerByJobId} component={AsyncJobApplicationPage} />

              <Route exact path={APP_ROUTES.ourClients} component={AsyncOurClientsPage} />
              <Route exact path={APP_ROUTES.sources} component={AsyncSourcesPage} />
              <Route exact path={APP_ROUTES.compareOurProducts} component={AsyncCompareOurProductsPage} />
              <Route exact path={APP_ROUTES.requestADemo} component={AsyncRequestDemoPage} />
              <Route exact path={APP_ROUTES.requestADemo350Club} component={AsyncRequestDemo350ClubPage} />

              <Route exact path={APP_ROUTES.briefings} component={AsyncBriefingsPage} />
              <Route exact path={APP_ROUTES.company} component={AsyncCompanyPage} />
              <Route exact path={APP_ROUTES.advisory} component={AsyncAdvisoryPage} />
              <Route exact path={APP_ROUTES.bespoke} component={AsyncBespokePage} />

              <Route exact path={APP_ROUTES.startTrial} component={AsyncStartTrialPage} />
              <Route exact path={APP_ROUTES.stripePayment} component={isUserAuthenticated(AsyncStripePaymentPage)} />
              <Route exact path={APP_ROUTES.subscriptionSuccess} component={AsyncSubscriptionSuccessPage} />
              <Route exact path={APP_ROUTES.subscriptionFailure} component={AsyncSubscriptionFailurePage} />
              <Route exact path={APP_ROUTES.acceptTrial} component={AsyncAcceptTrialPage} />
              <Route exact path={APP_ROUTES.acceptTrialToken} component={AsyncAcceptTrialPage} />
              <Route exact path={APP_ROUTES.paymentMethod} component={AsyncPaymentMethodPage} />
              <Route exact path={APP_ROUTES.subscriptionInfo} component={isUserAuthenticated(AsyncSubscriptionInfoPage)} />
              <Route exact path={APP_ROUTES.updateCard} component={isUserAuthenticated(AsyncUpdateCardPage)} />
              <Route exact path={APP_ROUTES.deleteCard} component={isUserAuthenticated(AsyncDeleteCardPage)} />
              <Route exact path={APP_ROUTES.magicLink} component={AsyncMagicLinkPage} />

              <Route exact path={APP_ROUTES.login} component={redirectAuthenticatedUser(AsyncLoginPage)} />
              <Route exact path={APP_ROUTES.secureLink} component={AsyncSecureLinkPage} />
              <Route exact path={APP_ROUTES.secureLinkSuccess} component={AsyncSecureLinkSuccessPage} />
              <Route exact path={APP_ROUTES.setPassword} component={AsyncSetPasswordPage} />
              <Route exact path={APP_ROUTES.resetPassword} component={AsyncResetPasswordPage} />
              <Route exact path={APP_ROUTES.resetPasswordEmailSent} component={isUserAuthenticated(AsyncResetPasswordEmailSentPage)} />
              <Route exact path={APP_ROUTES.contact} component={AsyncContactPage} />

              {/* do not change the order of company routes because the app will go wild */}
              <Route exact path={APP_ROUTES.dashboard} component={isUserAuthenticated(AsyncDashboardPage)} />
              <Route exact path={APP_ROUTES.companiesTrial} component={isUserAuthenticated(AsyncCompaniesTrialPage)} />
              <Route path={APP_ROUTES.companySettings} component={isUserAuthenticated(AsyncCompanySettingsPage)} />
              <Route path={APP_ROUTES.companyByCompanyId} component={isUserAuthenticated(AsyncCompanyDetailsPage)} />
              <Route path={APP_ROUTES.companiesBase} component={isUserAuthenticated(AsyncCompaniesPage)} />
              <Route exact path={APP_ROUTES.companyHashtags} component={isUserAuthenticated(AsyncCompanyHashtagsPage)} />
              <Route exact path={APP_ROUTES.myNews} component={isUserAuthenticated(AsyncMyNewsPage)} />
              <Route exact path={APP_ROUTES.myBriefingsFollowed} component={isUserAuthenticated(AsyncMyBriefingsFollowedPage)} />
              <Route path={APP_ROUTES.myBriefingsSettings} component={isUserAuthenticated(AsyncMyBriefingsSettingsPage)} />
              <Route path={APP_ROUTES.myBriefingsBase} component={isUserAuthenticated(AsyncMyBriefingsPage)} />
              <Route exact path={APP_ROUTES.search} component={isUserAuthenticated(AsyncSearchPage)} />

              <Route exact path={APP_ROUTES.articleBySectionIdAndCoverageId} component={isUserAuthenticated(AsyncArticlePage)} />
              <Route exact path={APP_ROUTES.articleBySectionIdAndSummaryId} component={isUserAuthenticated(AsyncArticlePage)} />
              <Route exact path={APP_ROUTES.articleByCoverageId} component={isUserAuthenticated(AsyncArticlePage)} />
              <Route exact path={APP_ROUTES.articleByContentId} component={isUserAuthenticated(AsyncArticlePage)} />
              <Route exact path={APP_ROUTES.myNewsByCoverageId} component={isUserAuthenticated(AsyncSingleCoveragePage)} />

              <Route path={APP_ROUTES.settings} component={isUserAuthenticated(AsyncSettingsPage)} />

              <Route exact path={APP_ROUTES.privacyPolicy} render={() => renderPdf(pdfPrivacy)} />
              <Route exact path={APP_ROUTES.acceptableUsePolicy} render={() => renderPdf(pdfAcceptable)} />
              <Route exact path={APP_ROUTES.termsAndConditionsB2C} render={() => renderPdf(pdfTerms)} />
              <Route exact path={APP_ROUTES.termsAndConditionsB2B} render={() => renderPdf(pdfTermsB2B)} />
              <Route exact path={APP_ROUTES.ftse350RankingsReport2019} render={() => renderPdf(pdfFTSE350RankingsReport2019)} />
              <Route exact path={APP_ROUTES.ftseRankingsReport2020Q1} render={() => renderPdf(pdfFTSERankingsReport2020Q1)} />
              <Route exact path={APP_ROUTES.ftseRankingsReport2020Q2} render={() => renderPdf(pdfFTSERankingsReport2020Q2)} />

              <Route exact path={APP_ROUTES.privateEquity20221007} render={() => renderPdf(pdfPrivateEquity20221007)} />
              <Route exact path={APP_ROUTES.privateEquity20221007pdf} render={() => renderPdf(pdfPrivateEquity20221007)} />
              <Route exact path={APP_ROUTES.privateEquity20221103} render={() => renderPdf(pdfPrivateEquity20221103)} />
              <Route exact path={APP_ROUTES.privateEquity20221103pdf} render={() => renderPdf(pdfPrivateEquity20221103)} />
              <Route exact path={APP_ROUTES.privateEquity20221202} render={() => renderPdf(pdfPrivateEquity20221202)} />
              <Route exact path={APP_ROUTES.privateEquity20221202pdf} render={() => renderPdf(pdfPrivateEquity20221202)} />
              <Route exact path={APP_ROUTES.privateEquity20230105} render={() => renderPdf(pdfPrivateEquity20230105)} />
              <Route exact path={APP_ROUTES.privateEquity20230105pdf} render={() => renderPdf(pdfPrivateEquity20230105)} />
              <Route exact path={APP_ROUTES.privateEquity20230207} render={() => renderPdf(pdfPrivateEquity20230207)} />
              <Route exact path={APP_ROUTES.privateEquity20230207pdf} render={() => renderPdf(pdfPrivateEquity20230207)} />
              <Route exact path={APP_ROUTES.privateEquity20230308} render={() => renderPdf(pdfPrivateEquity20230308)} />
              <Route exact path={APP_ROUTES.privateEquity20230308pdf} render={() => renderPdf(pdfPrivateEquity20230308)} />
              <Route exact path={APP_ROUTES.privateEquity20230411} render={() => renderPdf(pdfPrivateEquity20230411)} />
              <Route exact path={APP_ROUTES.privateEquity20230411pdf} render={() => renderPdf(pdfPrivateEquity20230411)} />
              <Route exact path={APP_ROUTES.privateEquity20230510} render={() => renderPdf(pdfPrivateEquity20230510)} />
              <Route exact path={APP_ROUTES.privateEquity20230510pdf} render={() => renderPdf(pdfPrivateEquity20230510)} />
              <Route exact path={APP_ROUTES.privateEquity20230531} render={() => renderPdf(pdfPrivateEquity20230531)} />
              <Route exact path={APP_ROUTES.privateEquity20230531pdf} render={() => renderPdf(pdfPrivateEquity20230531)} />

              {/* Temporary support old links mostly from emails */}
              <Route exact path={APP_ROUTES.oldResetPassword} component={AsyncResetPasswordPage} />
              <Route exact path={APP_ROUTES.oldSetPassword} component={AsyncSetPasswordPage} />
              <Route exact path={APP_ROUTES.oldArticleBySectionIdAndCoverageId} component={isUserAuthenticated(AsyncArticlePage)} />
              <Route exact path={APP_ROUTES.oldArticleBySectionIdAndSummaryId} component={isUserAuthenticated(AsyncArticlePage)} />
              <Route exact path={APP_ROUTES.oldMagicLink} component={AsyncMagicLinkPage} />
              <Route exact path={APP_ROUTES.oldPrivacyPolicy} render={() => renderPdf(pdfPrivacy)} />
              <Route exact path={APP_ROUTES.oldAcceptableUsePolicy} render={() => renderPdf(pdfAcceptable)} />
              <Route exact path={APP_ROUTES.oldTermsAndConditionsB2C} render={() => renderPdf(pdfTerms)} />
              <Route exact path={APP_ROUTES.oldTermsAndConditionsB2B} render={() => renderPdf(pdfTermsB2BWithDoubleSlash)} />
              <Route exact path={APP_ROUTES.oldTermsAndConditionsB2BWithDoubleSlash} render={() => renderPdf(pdfTermsB2BWithDoubleSlash)} />
              <Route exact path={APP_ROUTES.oldBriefcaseLogo} render={() => renderPdf(briefcaseLogo)} />

              {/* This is to support old unsubscribe links - we'll remove it soon */}
              <Route exact path={APP_ROUTES.clientCoverageEmailUnsubscribe} component={AsyncUnsubscribePage} />
              <Route exact path={APP_ROUTES.unsubscribeFromAll} component={AsyncUnsubscribePage} />
              <Route exact path={APP_ROUTES.oldUnsubscribeFromAll} component={AsyncUnsubscribePage} />
              <Route exact path={APP_ROUTES.emailUnsubscribe} component={AsyncUnsubscribePage} />
              <Route exact path={APP_ROUTES.oldEmailUnsubscribe} component={AsyncUnsubscribePage} />
              <Route exact path={APP_ROUTES.unsubscribeAll} component={AsyncUnsubscribePage} />
              <Route exact path={APP_ROUTES.unsubscribe} component={AsyncUnsubscribePage} />

              <Route path={APP_ROUTES.fallback} component={AsyncNotFoundPage} />
            </Switch>
            <Footer />
          </ScrollToTop>
        </ConnectedRouter>
      </IntercomProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { initializeApp, statrafLocationChangeEvent };

export default connect(mapStateToProps, mapDispatchToProps)(App);
