import { ACCEPT_TRIAL_FAILURE, ACCEPT_TRIAL_REQUEST, ACCEPT_TRIAL_SUCCESS } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  success: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCEPT_TRIAL_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ACCEPT_TRIAL_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
      };
    case ACCEPT_TRIAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
};
