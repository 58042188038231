import {
  SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_REQUEST,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_SUCCESS,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_FAILURE,
  SUBSCRIPTIONS_SELECT_SUBSCRIPTION_PLAN,
  SUBSCRIPTIONS_FETCH_CARDS_REQUEST,
  SUBSCRIPTIONS_FETCH_CARDS_SUCCESS,
  SUBSCRIPTIONS_FETCH_CARDS_FAILURE,
  SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_SUCCESS,
  SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_REQUEST,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_REQUEST,
  SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  plans: [],
  userSubscriptions: [],
  subscriptionsFetched: false,
  selectedPlan: null,
  errors: null,
  cards: {
    isLoading: false,
    items: [],
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        plans: action.payload,
        selectedPlan: action.payload[0]
      };
    case SUBSCRIPTIONS_FETCH_SUBSCRIPTION_PLANS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case SUBSCRIPTIONS_SELECT_SUBSCRIPTION_PLAN:
      return {
        ...state,
        selectedPlan: action.subscriptionPlan,
      };
    case SUBSCRIPTIONS_FETCH_CARDS_REQUEST: {
      return {
        ...state,
        cards: {
          ...state.cards,
          isLoading: true,
        }
      };
    }
    case SUBSCRIPTIONS_FETCH_CARDS_SUCCESS: {
      return {
        ...state,
        cards: {
          ...state.cards,
          isLoading: false,
          items: action.payload,
        }
      };
    }

    case SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_REQUEST: {
      return {
        ...state,
        cards: {
          ...state.cards,
          isLoading: true
        }
      };
    }

    case SUBSCRIPTIONS_SET_CARD_AS_DEFAULT_SUCCESS: {
      return {
        ...state,
        cards: {
          ...state.cards,
          isLoading: false,
          items: state.cards.items.map((item) => {
            if (item.id === action.payload.default_source) {
              return {
                ...item,
                default: true
              };
            }

            return {
              ...item,
              default: false
            };
          }),
        }
      };
    }

    case SUBSCRIPTIONS_FETCH_CARDS_FAILURE: {
      return {
        ...state,
        cards: {
          ...state.cards,
          isLoading: false,
          error: action.error,
        }
      };
    }

    case SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        subscriptionsFetched: true,
        userSubscriptions: action.payload,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
