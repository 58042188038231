/* eslint-disable camelcase */
import {
  HASHTAG_EMAIL_NOTIFICATIONS_OFF,
  HASHTAG_EMAIL_NOTIFICATIONS_ON,
  SETTINGS_NOTIFICATIONS_FETCHING,
  SETTINGS_NOTIFICATIONS_FETCHING_FAILURE,
  SETTINGS_NOTIFICATIONS_FETCHING_SUCCESS,
  HASHTAG_PUSH_NOTIFICATIONS_ON,
  HASHTAG_PUSH_NOTIFICATIONS_OFF
} from '../actions/actionTypes';

const initialState = {
  companies: [],
  briefingTypes: [],
  isFetching: false,
  error: null
};

const fetchingLabels = (state) => ({
  ...state,
  isFetching: true
});

const fetchingLabelsSuccess = (
  state,
  { payload: { companies, briefingTypes } }
) => ({
  ...state,
  isFetching: false,
  companies: [...companies],
  briefingTypes: [...briefingTypes]
});

const fetchingLabelsFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});

const processCollection = (collection, { type, id }, fieldName, fieldValue) => collection.map((el) => {
  if (el.type === type && el.id === id) {
    return {
      ...el,
      [fieldName]: fieldValue
    };
  }
  return el;
});

const toggleEmail = (state, item, email_notification) => ({
  ...state,
  companies: processCollection(
    state.companies,
    item,
    'email_notification',
    email_notification
  ),
  briefingTypes: processCollection(
    state.briefingTypes,
    item,
    'email_notification',
    email_notification
  )
});

const togglePush = (state, item, push_notification) => ({
  ...state,
  companies: processCollection(
    state.companies,
    item,
    'push_notification',
    push_notification
  ),
  briefingTypes: processCollection(
    state.briefingTypes,
    item,
    'push_notification',
    push_notification
  )
});

const selectEmail = (state, { payload: { item } }) => toggleEmail(state, item, true);
const unselectEmail = (state, { payload: { item } }) => toggleEmail(state, item, false);

const selectPush = (state, { payload: { item } }) => togglePush(state, item, true);
const unselectPush = (state, { payload: { item } }) => togglePush(state, item, false);

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_NOTIFICATIONS_FETCHING:
      return fetchingLabels(state, action);

    case SETTINGS_NOTIFICATIONS_FETCHING_SUCCESS:
      return fetchingLabelsSuccess(state, action);

    case SETTINGS_NOTIFICATIONS_FETCHING_FAILURE:
      return fetchingLabelsFailure(state, action);

    case HASHTAG_EMAIL_NOTIFICATIONS_OFF:
      return unselectEmail(state, action);

    case HASHTAG_EMAIL_NOTIFICATIONS_ON:
      return selectEmail(state, action);

    case HASHTAG_PUSH_NOTIFICATIONS_ON:
      return selectPush(state, action);

    case HASHTAG_PUSH_NOTIFICATIONS_OFF:
      return unselectPush(state, action);

    default:
      return state;
  }
};
