import { combineReducers } from 'redux';
import app from './app';
import jobApplication from './jobApplication';
import auth from './auth';
import companies from './companies';
import hashtags from './hashtags';
import myNews from './myNews';
import briefings from './briefings';
import unauthNews from './unauthNews';
import companyDetails from './companyDetails';
import account from './account';
import notifications from './notifications';
import unauthForms from './unauthForms';
import search from './search';
import article from './articles';
import unsubscribe from './unsubscribe';
import subscriptions from './subscriptions';
import acceptTrial from './acceptTrial';
import dashboard from './dashboard';
import newspapers from './newspapers';
import team from './team';
import { LOGOUT_USER } from '../actions/actionTypes';

const appReducer = combineReducers({
  app,
  jobApplication,
  auth,
  companies,
  hashtags,
  myNews,
  briefings,
  unauthNews,
  companyDetails,
  account,
  notifications,
  unauthForms,
  search,
  article,
  unsubscribe,
  subscriptions,
  acceptTrial,
  dashboard,
  newspapers,
  team
});

const initialState = appReducer({}, {});
/* eslint-disable no-param-reassign */
const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
