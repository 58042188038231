import {
  COMPANY_FEED_FETCHING,
  COMPANY_FEED_FETCHING_FAILURE,
  COMPANY_FEED_FETCHING_SUCCESS,
  COMPANY_FEED_MORE_FETCHING,
  COMPANY_FEED_MORE_FETCHING_FAILURE,
  COMPANY_FEED_MORE_FETCHING_SUCCESS,
  COMPANY_FEED_RESET,
  COMPANY_LABELS_FAILURE,
  COMPANY_LABELS_FETCHING,
  COMPANY_LABELS_SUCCESS,
} from '../actions/actionTypes';
import { mergeSections } from './myNews';

const initialState = {
  tabs: {},
  labels: [],
  links: {},
  isFetching: false,
  firstRequest: true,
  error: null,
  isFetchingLabels: false
};

const fetchingData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingSuccess = (
  state,
  { payload: { sections, links, companyId } }
) => ({
  ...state,
  isFetching: false,
  tabs: {
    ...state.tabs,
    [companyId]: {
      isFetching: false,
      firstRequest: false,
      sections: [...sections].map((section) => ({
        ...section,
        disabled: true
      })),
      links
    }
  }
});

const fetchingFailure = (state, { error: { error, companyId } }) => ({
  ...state,
  tabs: {
    ...state.tabs,
    [companyId]: {
      isFetching: false,
      error
    }
  }
});

const fetchingMoreData = (state, { payload: { companyId } }) => ({
  ...state,
  tabs: {
    ...state.tabs,
    [companyId]: {
      ...state.tabs[companyId],
      isFetching: true
    }
  }
});

const fetchingMoreSuccess = (state, { payload: { sections, links, companyId } }) => {
  const merged = mergeSections(state.tabs[companyId].sections, sections, links);
  if (merged) {
    return {
      ...state,
      tabs: {
        ...state.tabs,
        [companyId]: {
          ...state.tabs[companyId],
          ...merged,
          sections: merged.sections.map((section) => ({
            ...section,
            disabled: true
          }))
        }
      }
    };
  }

  return {
    ...state,
    tabs: {
      ...state.tabs,
      [companyId]: {
        ...state.tabs[companyId],
        sections: [...state.tabs[companyId].sections, ...sections].map((section) => ({
          ...section,
          disabled: true
        })),
        isFetching: false,
        links
      }
    }
  };
};

const fetchingMoreFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});

const fetchingLabels = (state) => ({
  ...state,
  isFetchingLabels: true
});

const fetchingLabelsSuccess = (state, { payload: { items } }) => ({
  ...state,
  isFetchingLabels: false,
  labels: [...(items || [])]
    .sort((itemA, itemB) => {
      const nameA = itemA.meta && itemA.meta.subtitle ? itemA.meta.subtitle : itemA.name;
      const nameB = itemB.meta && itemB.meta.subtitle ? itemB.meta.subtitle : itemB.name;
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
    .map((item) => ({
      ...item,
      name: item.meta && item.meta.subtitle ? item.meta.subtitle : item.name
    })),
  // tabs: {
  //   latest: initialTab,
  //   ...(items || []).reduce((acc, el) => {
  //     acc[el.id] = initialTab;
  //     return acc;
  //   }, {})
  // }
});

const fetchingLabelsFailure = (state) => ({
  ...state,
  isFetchingLabels: false,
  error: true
});

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_LABELS_FETCHING:
      return fetchingLabels(state, action);

    case COMPANY_LABELS_SUCCESS:
      return fetchingLabelsSuccess(state, action);

    case COMPANY_LABELS_FAILURE:
      return fetchingLabelsFailure(state, action);

    case COMPANY_FEED_FETCHING:
      return fetchingData(state, action);

    case COMPANY_FEED_FETCHING_SUCCESS:
      return fetchingSuccess(state, action);

    case COMPANY_FEED_FETCHING_FAILURE:
      return fetchingFailure(state, action);

    case COMPANY_FEED_MORE_FETCHING:
      return fetchingMoreData(state, action);

    case COMPANY_FEED_MORE_FETCHING_SUCCESS:
      return fetchingMoreSuccess(state, action);

    case COMPANY_FEED_MORE_FETCHING_FAILURE:
      return fetchingMoreFailure(state, action);

    case COMPANY_FEED_RESET:
      return initialState;

    default:
      return state;
  }
};
