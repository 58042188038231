/* eslint-disable react/no-unused-state */
import React from 'react';
import { APP_ROUTES } from '../constants/routes';
import Rollbar from '../rollbar';

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error) {
    this.setState({ hasError: true });
    Rollbar.error(error);
    this.props.history.push(APP_ROUTES.somethingWentWrong);
  }

  render() {
    return this.props.children;
  }
}
