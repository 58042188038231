import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { Flex, Box } from '@rebass/grid';
import { isUnauthorizedRoute } from '../../utils';
import Container from '../common/Container';
import TextLink from '../common/TextLink';
import Svg from '../common/Svg';
import locale from './Footer.json';
import { media } from '../../utils/styleUtils';

const PdfLink = styled.a`
  color: #3c475a;
  font-size: 14px;
`;
const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.headerDarkBg};
  color: #fff;
  margin-top: auto;
`;
const SectionTitle = styled.div`
  font-size: 16px;
  padding-bottom: 20px;
  color: #fff;
  ${media.sm`
    padding-bottom: 14px;
  `}
  text-align: center;
`;
const Download = styled(SectionTitle)`
  font-size: 26px;
  font-weight: 500;
  color: ${(props) => props.theme.color.highlight};
`;
const LinkWrapper = styled.div`
  min-height: 30px;
  ${media.sm`
    display: ${(props) => props.isEmpty && 'none'};
  `}
`;
const Img = styled.img`
  max-height: ${(props) => (props.height ? props.height : 25)}px;
  height: auto;
  width: 100%;
  max-width: 180px;
`;
const RwdFlex = styled(Flex)`
  ${media.sm`
    display: none;
  `}
`;
const FooterSection = styled(Box)`
  text-align: center;
  padding-bottom: 60px;
  font-weight: 500;
  ${media.sm`
    padding-bottom: 30px;
    display: ${(props) => (props.rwd ? 'block' : 'none')};
  `}
`;
const ExternalLink = styled.a`
  color: #fff;
  :hover {
    color: #fff;
  }
`;

const getCurrentYear = () => new Date().getFullYear();

const Footer = () => (
  <>
    <Wrapper>
      <Container>
        <Box py={46} px={15}>
          <Svg maxHeight={46} src="/images/svg-titles/briefcase-news-human-intelligence.svg" />
        </Box>
        {isUnauthorizedRoute()
          && (
          <Flex alignItems="flex-start" justifyContent="space-between" flexWrap="wrap" pt={[30, 30, 0]}>
            {locale.content.map((item, index) => (
              <FooterSection rwd={item.rwd ? 1 : 0} key={`footerSection${index}`} width={[1, 1 / 2, 1 / 4]} px={10}>
                <SectionTitle>{item.sectionTitle}</SectionTitle>
                {item.sectionItems.map((sectionItem, i) => (
                  <LinkWrapper isEmpty={isEmpty(sectionItem.title)} key={`footerlink${i}`}>
                    {(sectionItem.externalLink && !sectionItem.link)
                      && <ExternalLink href={sectionItem.externalLink}>{sectionItem.title}</ExternalLink>}
                    {(!sectionItem.externalLink && sectionItem.link)
                      && <TextLink white to={sectionItem.link}>{sectionItem.title}</TextLink>}
                    {(!sectionItem.externalLink && !sectionItem.link)
                      && sectionItem.title}
                  </LinkWrapper>
                ))}
              </FooterSection>
            ))}
          </Flex>
          )}
        <Download>Download the Briefcase News app</Download>
        <Flex alignItems="center" pb={52} pt={30} flexDirection={['column', 'row']}>
          <Flex width={[1, 1, 1 / 4]} alignItems="center" justifyContent="center" order={[2, 1]}>
            <a href="//www.linkedin.com/company/briefcase.news" target="_blank" rel="noreferrer noopener">
              <Img width={50} src="/images/icons/icon-linkedin-white.svg" alt="linkedin" />
            </a>
          </Flex>
          <Flex width={[1, 1, 1 / 2]} pr={[0, 60, 0]} alignItems="center" justifyContent="center" order={[1, 2]} flexDirection={['column', 'row']}>
            <Box mb={[20, 0]} mr={[0, 20]}>
              <a href="https://itunes.apple.com/app/id964156391" target="_blank" rel="noreferrer noopener">
                <Img height={56} src="/images/icons/logo-appstore.svg" alt="App Store" />
              </a>
            </Box>
            <Box mb={[40, 0, 0]} mr={[0, 20, 0]}>
              <a href="https://play.google.com/store/apps/details?id=com.briefcase" target="_blank" rel="noreferrer noopener">
                <Img height={56} src="/images/icons/logo-googleplay.svg" alt="Google play" />
              </a>
            </Box>
          </Flex>
          <RwdFlex width={[1, 1, 1 / 4]} alignItems="center" justifyContent="center" order={3}>
            <a href="mailto:contact@briefcase.news">
              <Img src="/images/icons/icon-contactus-white.svg" alt="contact us" />
            </a>
          </RwdFlex>
        </Flex>
      </Container>
    </Wrapper>
    <Container>
      <Flex justifyContent="space-between" alignItems="center" flexDirection={['column', 'row']} py={[10, 28]} px={[24, 10]}>
        <Box my={[25, 0]} order={[4, 1]} style={{ color: '#3c475a', fontSize: 14 }}>
          Briefcase.news ©
          { getCurrentYear() }
        </Box>
        <Box my={[10, 0]} order={[1, 2]}>
          <PdfLink href="https://43d5e456ea260e048c71-576849cded313c4550fa1368505958ce.ssl.cf3.rackcdn.com/pdf/Briefcase_News_Privacy_and_cookies_policy_10_March_2020.pdf">Privacy policy</PdfLink>
        </Box>
        <Box my={[10, 0]} order={[2, 3]}>
          <PdfLink href="https://43d5e456ea260e048c71-576849cded313c4550fa1368505958ce.ssl.cf3.rackcdn.com/pdf/Briefcase_News_Business_Terms_and_Conditions_23_May_2019.pdf">Terms and conditions</PdfLink>
        </Box>
        <Box my={[10, 0]} order={[3, 4]}>
          <PdfLink href="https://43d5e456ea260e048c71-576849cded313c4550fa1368505958ce.ssl.cf3.rackcdn.com/pdf/Briefcase_News_Acceptable_use_policy_10_March_2020.pdf">Acceptable use</PdfLink>
        </Box>
      </Flex>
    </Container>
  </>
);

export default Footer;
