import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { RadioRoundedButton } from '../common/formFields/RadioInput';
import { PrimaryInput } from '../common/formFields/TextInput';
import { FormButton } from '../common/Button';
import Text from '../common/Text';
import { normalizeEmail } from './formNormalizers';
import {
  composeValidators,
  mustBeValidEmail,
  required
} from './formValidators';
import { contactUs, resetForm } from '../../actions/unauthForms';

class ContactUsForm extends Component {
  componentDidMount() {
    const { message } = this.props.form;
    if (!isEmpty(message)) {
      this.props.resetForm();
    }
  }

  onSubmit = async (values) => {
    const contactData = pick(values, ['subject', 'fullName', 'email', 'message']);
    await this.props.onSubmit({
      ...contactData
    });
    return this.props.form.errors;
  }

  render() {
    const {
      message,
      isLoading,
    } = this.props.form;

    if (message) {
      return (
        <Box my={100}>
          <Text textAlign="center">{message}</Text>
        </Box>
      );
    }

    return (
      <>
        <Text textAlign="left" color="#505A6B" mb={30}>
          Please select the area you are seeking help so that we can assist you as soon as possible.
        </Text>
        <Form
          onSubmit={this.onSubmit}
          initialValues={{ subject: 'Technical support' }}
          render={({ handleSubmit, submitting, errors }) => (
            <form onSubmit={handleSubmit}>
              <Flex my={20} flexWrap="wrap" justifyContent="space-between">
                <Box mb={[10, 0]}>
                  <Field validate={required} name="subject" type="radio" value="Technical support">
                    {({ input, meta }) => (
                      <RadioRoundedButton
                        inputFormProps={{ ...input }}
                        meta={meta}
                        id="radioTech"
                        label="Technical support"
                        name="subject"
                      />
                    )}
                  </Field>
                </Box>
                <Box>
                  <Field validate={required} name="subject" type="radio" value="Other questions">
                    {({ input, meta }) => (
                      <RadioRoundedButton
                        inputFormProps={{ ...input }}
                        meta={meta}
                        id="radioOther"
                        label="Other questions"
                        name="subject"
                      />
                    )}
                  </Field>
                </Box>
              </Flex>
              <Field
                name="fullName"
                validate={required}
              >
                {({ input, meta }) => (
                  <PrimaryInput
                    inputFormProps={{ ...input }}
                    label="Full name"
                    meta={meta}
                  />
                )}
              </Field>
              <Field
                name="email"
                validate={composeValidators(required, mustBeValidEmail)}
                parse={normalizeEmail}
              >
                {({ input, meta }) => (
                  <PrimaryInput
                    inputFormProps={{ ...input }}
                    label="Email address"
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="message" validate={required}>
                {({ input, meta }) => (
                  <PrimaryInput
                    multiline
                    inputFormProps={{ ...input }}
                    label="Your message"
                    meta={meta}
                  />
                )}
              </Field>
              <Flex
                justifyContent="flex-end"
                alignItems="center"
                mt={25}
              >
                <FormButton
                  type="submit"
                  disabled={submitting || !isEmpty(errors)}
                >
                  {isLoading ? 'Sending...' : 'Send'}
                </FormButton>
              </Flex>
            </form>
          )}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  onSubmit: contactUs,
  resetForm,
};

const mapStateToProps = (state) => ({
  form: state.unauthForms
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsForm);
