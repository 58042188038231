import {
  RESET_STORED_DATA,
  SETTINGS_ACCOUNT_USER_FETCHING,
  SETTINGS_ACCOUNT_USER_FETCHING_FAILURE,
  SETTINGS_ACCOUNT_USER_FETCHING_SUCCESS,
  SETTINGS_ACCOUNT_USER_UPDATING,
  SETTINGS_ACCOUNT_USER_UPDATING_FAILURE,
  SETTINGS_ACCOUNT_USER_UPDATING_SUCCESS
} from '../actions/actionTypes';

const initialState = {
  data: {
    dashboardSettings: { enabled: false },
    allEmailNotificationsEnabled: false,
    allPushNotificationsEnabled: false,
    company: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    title: undefined,
    subscriptions: [],
  },
  user: localStorage.getItem('firstName') || (localStorage.getItem('email') ? localStorage.getItem('email').substring(0, localStorage.getItem('email').lastIndexOf('@')) : ''),
  emailSentModal: false,
  isFetching: false,
  isUpdating: false,
  error: null
};

const fetchingUserData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingUserDataSuccess = (state, { payload }) => ({
  ...state,
  isFetching: false,
  data: { ...payload },
  user: payload.firstName || (payload.email ? payload.email.substring(0, payload.email.lastIndexOf('@')) : '')
});

const fetchingUserDataFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});

const updatingUserData = (state) => ({
  ...state,
  isUpdating: true
});

const updatingUserDataSuccess = (state, { payload }) => ({
  ...state,
  isUpdating: false,
  data: {
    ...state.data,
    ...payload
  },
  user: payload.firstName || (payload.email ? payload.email.substring(0, payload.email.lastIndexOf('@')) : '')
});

const updatingUserDataFailure = (state) => ({
  ...state,
  isUpdating: false,
  error: true
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_ACCOUNT_USER_FETCHING:
      return fetchingUserData(state, action);

    case SETTINGS_ACCOUNT_USER_FETCHING_SUCCESS:
      return fetchingUserDataSuccess(state, action);

    case SETTINGS_ACCOUNT_USER_FETCHING_FAILURE:
      return fetchingUserDataFailure(state, action);

    case SETTINGS_ACCOUNT_USER_UPDATING:
      return updatingUserData(state, action);

    case SETTINGS_ACCOUNT_USER_UPDATING_SUCCESS:
      return updatingUserDataSuccess(state, action);

    case SETTINGS_ACCOUNT_USER_UPDATING_FAILURE:
      return updatingUserDataFailure(state, action);

    case RESET_STORED_DATA:
      return initialState;

    default:
      return state;
  }
};
