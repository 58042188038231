import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-right: ${(props) => props.mr}px;
  margin-top: ${(props) => props.mt}px;
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
`;

Text.defaultProps = {
  fontSize: 16,
  mb: 0,
  mt: 0,
  mr: 0,
  textAlign: 'left',
};

Text.propTypes = {
  fontSize: PropTypes.number,
  mb: PropTypes.number,
  mt: PropTypes.number,
  textAlign: PropTypes.string,
};

export default Text;
