import ReactGA from 'react-ga4';
import { history } from './store';

export const initializeGA = (userId) => {
  const { REACT_APP_GOOGLE_ANALYTICS } = process.env;
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS, { gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' } });
  ReactGA.set({
    page: window.location.pathname,
    userId: userId || 'anonymous',
    dimension2: userId || 'anonymous'
  });
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  history.listen((location) => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  });
};
