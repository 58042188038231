import {
  NEWSPAPERS,
  NEWSPAPERS_SUCCESS,
  NEWSPAPERS_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  newspapers: [],
  isFetchingNewspapers: false,
  error: null,
};

const fetchingData = (state) => ({
  ...state,
  isFetchingNewspapers: true,
  newspapers: []
});

const fetchingSuccess = (state, { payload }) => ({
  ...state,
  newspapers: [...payload.newspapers],
  isFetchingNewspapers: false,
});

const fetchingFailure = (state) => ({
  ...state,
  isFetchingNewspapers: false,
  newspapers: [],
  error: true
});

export default (state = initialState, action) => {
  switch (action.type) {

    case NEWSPAPERS:
      return fetchingData(state, action);

    case NEWSPAPERS_SUCCESS:
      return fetchingSuccess(state, action);

    case NEWSPAPERS_FAILURE:
      return fetchingFailure(state, action);

    default:
      return state;
  }
};
