import styled from 'styled-components';
import { Box } from '@rebass/grid';
import { media } from '../../utils/styleUtils';

const Container = styled(Box)`
  max-width: 1134px;
  width: 100%;
`;
Container.defaultProps = {
  mx: 'auto'
};

export default Container;

export const ContainerWithPaddings = styled(Container)`
  max-width: 1164px;
`;

export const InnerContainerWithShadow = styled.div`
  margin: ${(props) => (props.single ? '60px auto 90px' : '0 auto 20px')};
  padding: ${(props) => (props.single ? '48px 30px 90px' : '30px')};
  max-width: 940px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
`;

export const CardWrapper = styled.div`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
`;

export const ContainerWithBackground = styled.div`
  background-image: url(${(props) => props.bg});
  background-size: cover;
  min-height: calc(100vh - 70px);
  padding: 60px 30px;
  ${media.xs`
    padding: 60px 15px;
  `}
`;
ContainerWithBackground.defaultProps = {
  bg: '/images/bgs/full-skater.jpg'
};

export const CenteredWindow = styled.div`
  max-width: 486px;
  margin: 0 auto;
  padding: 30px;
  background-color: ${(props) => props.theme.color.boxLightBg};
`;
