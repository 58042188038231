import axios from 'axios';

const STATRAF_TOKEN = process.env.REACT_APP_STATRAF_TOKEN;
const DEFAULT_PAYLOAD = {
  action: 'view',
  category: 'User',
  location: null,
  medium: 'web',
  object_id: null,
  object_name: null,
  path: null,
  screen_resolution: `${window.screen.availWidth}x${window.screen.availHeight}`,
  screen_viewport: `${window.innerWidth}x${window.innerHeight}`,
  title: 'Briefcase.news',
  token: STATRAF_TOKEN,
  type: 'pageview',
  subscriptions: (localStorage != null && localStorage.getItem('subscriptions') != null) ? JSON.parse(localStorage.getItem('subscriptions')) : [],
  user_id: null,
  user_email: null,
  version: 1
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_STATRAF_URL,
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [
    (data) => JSON.stringify(
      {
        ...DEFAULT_PAYLOAD,
        ...data
      },
      (k, v) => (v === undefined ? null : v)
    )
  ]
});

export default axiosInstance;
