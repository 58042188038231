import { TOGGLE_CONTACT_US_MODAL } from '../actions/actionTypes';

const initialState = {
  isContactUsOpened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CONTACT_US_MODAL:
      return {
        isContactUsOpened: !state.isContactUsOpened
      };
    default:
      return state;
  }
};
