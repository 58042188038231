import {
  HASHTAG_FOLLOW,
  HASHTAG_UNFOLLOW,
  RESET_STORED_DATA,
  SEARCH_FETCHING,
  SEARCH_FETCHING_FAILURE,
  SEARCH_FETCHING_SUCCESS,
  SEARCH_MORE_FETCHING,
  SEARCH_MORE_FETCHING_FAILURE,
  SEARCH_MORE_FETCHING_SUCCESS,
  SEARCH_RESET,
  SEARCH_SEARCH_TEXT
} from '../actions/actionTypes';

const initialState = () => ({
  sections: [],
  links: {},
  isFetching: false,
  firstRequest: true,
  error: null,
  searchText: ''
});

const setSearchText = (state, { text }) => ({
  ...state,
  searchText: text
});

const fetchingData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingSuccess = (state, { payload: { sections, links } }) => ({
  ...state,
  isFetching: false,
  sections: [...sections],
  firstRequest: false,
  links
});

const fetchingFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});

const fetchingMoreData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingMoreSuccess = (state, { payload: { sections, links } }) => ({
  ...state,
  isFetching: false,
  sections: [...state.sections, ...sections],
  links
});

const fetchingMoreFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});


const toggleFollow = (state, item, follow) => {
  const sections = state.sections.map((section) => {
    const data = section.data.map((el) => {
      if (el.id === item.id) {
        return {
          ...el,
          follow
        };
      }
      return el;
    });
    return {
      ...section,
      data
    };
  });

  return {
    ...state,
    sections
  };
};

const follow = (state, { payload: { item } }) => toggleFollow(state, item, true);
const unfollow = (state, { payload: { item } }) => toggleFollow(state, item, false);

export default (state = initialState(), action) => {
  switch (action.type) {

    case HASHTAG_UNFOLLOW:
      return unfollow(state, action);

    case HASHTAG_FOLLOW:
      return follow(state, action);

    case SEARCH_SEARCH_TEXT:
      return setSearchText(state, action);

    case SEARCH_FETCHING:
      return fetchingData(state, action);

    case SEARCH_FETCHING_SUCCESS:
      return fetchingSuccess(state, action);

    case SEARCH_FETCHING_FAILURE:
      return fetchingFailure(state, action);

    case SEARCH_MORE_FETCHING:
      return fetchingMoreData(state, action);

    case SEARCH_MORE_FETCHING_SUCCESS:
      return fetchingMoreSuccess(state, action);

    case SEARCH_MORE_FETCHING_FAILURE:
      return fetchingMoreFailure(state, action);

    case RESET_STORED_DATA:
    case SEARCH_RESET:
      return initialState();

    default:
      return state;
  }
};
