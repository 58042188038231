export const APP_ROUTES = {
  base: '/',
  dashboard: '/dashboard',
  news: '/news',
  howWeDoIt: '/how-we-do-it',
  whatWeDo: '/what-we-do',
  team: '/team',
  careers: '/careers',
  careerByJobId: '/careers/:jobId',
  ourClients: '/our-clients',
  sources: '/sources',
  compareOurProducts: '/compare-our-products',
  requestADemo: '/request-a-demo',
  requestADemo350Club: '/the350-club-members',
  briefings: '/briefings',
  company: '/company',
  advisory: '/advisory',
  bespoke: '/bespoke',
  login: '/login',
  secureLink: '/secure-link',
  secureLinkSuccess: '/secure-link/success',
  setPassword: '/set-password/:token',
  companiesBase: '/companies',
  companies: '/companies/following',
  companiesByLabelId: '/companies/:labelId',
  companiesTrial: '/companies/trial',
  companyHashtags: '/company-hashtags',
  companyByCompanyId: '/companies/:labelId/:companyId',
  companySettings: '/companies/:labelId/:companyId/settings',
  companyNewsByLabelId: '/companies/:labelId/:companyId/:labelNewsId',
  myNews: '/my-news',
  myNewsByCoverageId: '/my-news/:clientCoverageId',
  myBriefingsBase: '/my-briefings',
  myBriefings: '/my-briefings/following',
  myBriefingsFollowed: '/my-briefings/followed',
  myBriefingsByLabelId: '/my-briefings/:labelId',
  myBriefingsSettings: '/my-briefings/:labelId/settings',
  search: '/search',
  articleBySectionIdAndCoverageId: '/briefing-types/sections/:sectionId/coverages/:coverageId/article',
  articleBySectionIdAndSummaryId: '/briefing-types/:sectionId/summaries/:summaryId/article',
  articleByCoverageId: '/article/:coverageId',
  articleByContentId: '/client/section/content/:contentId/article', // backward compatibility for old mobile apps
  settings: '/settings',
  account: '/settings/account',
  resetPasswordEmailSent: '/reset-password',
  oldResetPassword: '/change_password/:token',
  resetPassword: '/change-password/:token',
  personalData: '/settings/account/personal-data',
  changePassword: '/settings/account/change-password',
  subscriptionInfo: '/settings/subscription-info/:id',
  notifications: '/settings/notifications',
  contactUs: '/settings/contact-us',
  magicLink: '/magic-link/:token',
  privacyPolicy: '/privacy-policy',
  acceptableUsePolicy: '/acceptable-use-policy',
  termsAndConditionsB2C: '/terms-and-conditions-B2C',
  termsAndConditionsB2B: '/terms-and-conditions-B2B',
  oldSetPassword: '/set_password/:token',
  oldArticleBySectionIdAndCoverageId: '/briefing_types/sections/:sectionId/coverages/:coverageId/article',
  oldArticleBySectionIdAndSummaryId: '/briefing_types/:sectionId/summaries/:summaryId/article',
  oldMagicLink: '/magic_link/:token',
  oldPrivacyPolicy: '/privacy_policy',
  oldAcceptableUsePolicy: '/acceptable_use_policy',
  oldTermsAndConditionsB2C: '/terms_and_conditions_B2C',
  oldTermsAndConditionsB2B: '/terms_and_conditions_B2B',
  oldTermsAndConditionsB2BWithDoubleSlash: '//terms_and_conditions_B2B',
  oldBriefcaseLogo: '/images/logos/briefcase.png',
  somethingWentWrong: '/something-went-wrong',
  startTrial: '/start-trial',
  cancelSubscription: '/settings/account/cancel-subscription',
  subscriptionSuccess: '/subscription-success',
  subscriptionFailure: '/subscription-failure',
  stripePayment: '/stripe-payment',
  acceptTrial: '/ahoy/messages/:token/click',
  acceptTrialToken: '/accept-trial/:token',
  paymentMethod: '/payment-method',
  updateCard: '/update-card',
  deleteCard: '/delete-card',
  clientCoverageEmailUnsubscribe: '/client_coverage_email_unsubscribe/:token',
  unsubscribeFromAll: '/unsubscribe-from-all/:token',
  oldUnsubscribeFromAll: '/unsubscribe_from_all/:token',
  emailUnsubscribe: '/email-unsubscribe/:token',
  oldEmailUnsubscribe: '/email_unsubscribe/:token',
  unsubscribeAll: '/unsubscribe-all/:token',
  unsubscribe: '/unsubscribe/:token',
  ftse350RankingsReport2019: '/FTSE_350_Rankings_Report_Dec_2019',
  ftseRankingsReport2020Q1: '/FTSE_Rankings_Report_Q1_2020',
  ftseRankingsReport2020Q2: '/FTSE_Rankings_Report_Q2_2020',
  privateEquity20221007: '/Private_Equity_20221007',
  privateEquity20221007pdf: '/Private_Equity_20221007.pdf',
  privateEquity20221103: '/Private_Equity_20221103',
  privateEquity20221103pdf: '/Private_Equity_20221103.pdf',
  privateEquity20221202: '/Private_Equity_20221202',
  privateEquity20221202pdf: '/Private_Equity_20221202.pdf',
  privateEquity20230105: '/Private_Equity_20230105',
  privateEquity20230105pdf: '/Private_Equity_20230105.pdf',
  privateEquity20230207: '/Private_Equity_20230207',
  privateEquity20230207pdf: '/Private_Equity_20230207.pdf',
  privateEquity20230308: '/Private_Equity_20230308',
  privateEquity20230308pdf: '/Private_Equity_20230308.pdf',
  privateEquity20230411: '/Private_Equity_20230411',
  privateEquity20230411pdf: '/Private_Equity_20230411.pdf',
  privateEquity20230510: '/Private_Equity_20230510',
  privateEquity20230510pdf: '/Private_Equity_20230510.pdf',
  privateEquity20230531: '/Private_Equity_20230531',
  privateEquity20230531pdf: '/Private_Equity_20230531.pdf',
  contact: '/contact',
  fallback: '*',
};

export const UNAUTHORIZED_APP_ROUTES = [
  APP_ROUTES.base,
  APP_ROUTES.news,
  APP_ROUTES.howWeDoIt,
  APP_ROUTES.whatWeDo,
  APP_ROUTES.team,
  APP_ROUTES.careers,
  APP_ROUTES.careerByJobId,
  APP_ROUTES.ourClients,
  APP_ROUTES.compareOurProducts,
  APP_ROUTES.requestADemo,
  APP_ROUTES.briefings,
  APP_ROUTES.company,
  APP_ROUTES.advisory,
  APP_ROUTES.bespoke,
  APP_ROUTES.magicLink,
  APP_ROUTES.login,
  APP_ROUTES.secureLink,
  APP_ROUTES.secureLinkSuccess,
  APP_ROUTES.resetPassword,
  APP_ROUTES.oldResetPassword,
  APP_ROUTES.setPassword,
  APP_ROUTES.oldSetPassword,
  APP_ROUTES.oldArticleBySectionIdAndCoverageId,
  APP_ROUTES.oldArticleBySectionIdAndSummaryId,
  APP_ROUTES.oldMagicLink,
  APP_ROUTES.oldPrivacyPolicy,
  APP_ROUTES.oldAcceptableUsePolicy,
  APP_ROUTES.oldTermsAndConditionsB2C,
  APP_ROUTES.oldTermsAndConditionsB2B,
  APP_ROUTES.privacyPolicy,
  APP_ROUTES.ftse350RankingsReport2019,
  APP_ROUTES.ftseRankingsReport2020Q1,
  APP_ROUTES.ftseRankingsReport2020Q2,
  APP_ROUTES.privateEquity20221007,
  APP_ROUTES.privateEquity20221007pdf,
  APP_ROUTES.privateEquity20221103,
  APP_ROUTES.privateEquity20221103pdf,
  APP_ROUTES.privateEquity20221202,
  APP_ROUTES.privateEquity20221202pdf,
  APP_ROUTES.privateEquity20230105,
  APP_ROUTES.privateEquity20230105pdf,
  APP_ROUTES.privateEquity20230207,
  APP_ROUTES.privateEquity20230207pdf,
  APP_ROUTES.privateEquity20230308,
  APP_ROUTES.privateEquity20230308pdf,
  APP_ROUTES.privateEquity20230411,
  APP_ROUTES.privateEquity20230411pdf,
  APP_ROUTES.privateEquity20230510,
  APP_ROUTES.privateEquity20230510pdf,
  APP_ROUTES.privateEquity20230531,
  APP_ROUTES.privateEquity20230531pdf,
];
