import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export const composeValidators = (...validators) => (value, values) => validators.reduce((error, validator) => error || validator(value, values), undefined);

export const required = (value) => (value ? undefined : 'This field is required');

export const mustBeValidPhoneNumber = (value) => {
  const trimmed = value.replace(/[^+a-zA-Z0-9]+/g, '');
  return trimmed.length < 14 ? 'This field has to be a valid phone number' : undefined;
};

export const mustBeValidEmail = (value) => (value.includes('@') ? undefined : 'Invalid email adress');

export const passwordsMustMatch = (value, values) => ((value === values.password || value === values.newPassword) ? undefined : 'Password confirmation must match');

export const passwordComplexity = (value) => {
  if (value.length < 6) return 'Password needs to be 6 characters or longer.';
  if (value.match(new RegExp(/[a-z]/)) === null) return 'Password needs to contain at least one lower case character (a-z).';
  if (value.match(new RegExp(/[A-Z]/)) === null) return 'Password needs to contain at least one upper case character (A-Z).';
  if (value.match(new RegExp(/\d/)) === null) return 'Password needs to contain at least one number (0-9).';
  if (value.match(new RegExp(/[`~!@#$%^&*()\-_=+[{\]};:'"\\|,<.>/?]/)) === null) return 'Password needs to contain at least one special character (e.g. !, @, & or #).';
  return undefined;
};

export const mustBeValidExtension = (value) => {
  const extension = value.name.split('.').pop();
  const allowedExtensions = ['pdf', 'doc', 'docx'];
  return isEmpty(allowedExtensions.filter((ext) => ext === extension))
    ? 'File must be pdf, doc or docx'
    : undefined;
};

export const mustBeValidStripeElement = (value) => get(value, 'error.message');
