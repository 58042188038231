import {
  ARTICLE_FETCHING,
  ARTICLE_FETCHING_FAILURE,
  ARTICLE_FETCHING_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  article: {},
  isFetching: false,
  firstRequest: true,
  error: null,
};

const fetchingData = (state) => ({
  ...state,
  isFetching: true
});

const fetchingSuccess = (state, { payload: { article } }) => ({
  ...state,
  article: article ? {
    ...article,
  } : {},
  isFetching: false,
  firstRequest: false,
});

const fetchingFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true
});

export default (state = initialState, action) => {
  switch (action.type) {

    case ARTICLE_FETCHING:
      return fetchingData(state, action);

    case ARTICLE_FETCHING_SUCCESS:
      return fetchingSuccess(state, action);

    case ARTICLE_FETCHING_FAILURE:
      return fetchingFailure(state, action);

    default:
      return state;
  }
};
