const path = (...attr) => [...attr].filter((el) => el).join('/');
const resolveCompanies = (id) => {
  if (id === 'following') {
    return path('companies/followed');
  }
  if (id === 'all') {
    return path('companies/all?limit=1000');
  }
  return path(`companies/sectors/${id}`);
};

const follows = (id) => path(`hashtags/${id}`);
const contents = (id) => path(`contents/${id}`);
const contentsForChildren = (id) => path(`contents/for_children/${id}`);
const contentsForClientCoverage = (id) => path(`contents/for_external_id/${id}`);
const companiesGroups = (groupId) => path(`companies/sectors/${groupId}`);
const search = (text) => path(`search?text=${text}`);
const articlesCoverage = (clientCoverageId) => path(`articles?clientCoverageId=${clientCoverageId}`);
const articlesSummary = (summaryId) => path(`articles?summaryId=${summaryId}`);
const articlesContent = (contentId, token) => {
  if (token) {
    return path(`articles?contentId=${contentId}&token=${token}`);
  }
  return path(`articles?contentId=${contentId}`);
};
const companySections = (companyId) => path(`companies/${companyId}/sections`);
const unsubscribe = (method, token) => path(`unsubscribe/${method}/${token}`);
const briefings = (id) => (id === 'following' ? path('my-news/briefings') : path(`contents/${id}`));
const companies = (id) => resolveCompanies(id);
const companyDetails = (id) => (id === 'latest' ? path(`contents/for_children/${id}`) : path(`contents/${id}`));
// const companyPublications = (companyId, sectionName) => path(`companies/${companyId}/sections/${encodeURIComponent(sectionName)}/publications`);
const companyPublications = (companyId, sectionId) => path(`v2/companies/${companyId}/sections/${sectionId}/publications`);
const contentPublicationCounters = (from, to) => path(`dashboard/contentPublicationCounters?from=${from}&to=${to}`);
const publicationsByCompanies = (from, to) => path(`dashboard/plots/publications-by-companies?from=${from}&to=${to}`);
const publicationsBySources = (from, to) => path(`dashboard/plots/publications-by-newspaper?from=${from}&to=${to}`);
const publicationsBySourcesForCompanies = (from, to) => path(`dashboard/plots/company-publications-by-newspaper?from=${from}&to=${to}`);
const publicationsBySentimentsForCompanies = (from, to) => path(`dashboard/plots/publications-by-sentiment-for-companies?from=${from}&to=${to}`);
const publicationsBySentimentsForNewspapers = (from, to) => path(`dashboard/plots/publications-by-sentiment-for-newspapers?from=${from}&to=${to}`);
const journalistsBySentimentsForCompanies = (from, to) => path(`dashboard/plots/top-authors?from=${from}&to=${to}`);

export default {
  host: path(),
  dashboard: {
    newspapers: path('/dashboard/newspapers'),
    companies: path('/dashboard/companies'),
    sectors: path('/dashboard/sectors'),
    contentPublicationCounters,
    publicationsByCompanies,
    publicationsBySources,
    publicationsBySourcesForCompanies,
    publicationsBySentimentsForCompanies,
    publicationsBySentimentsForNewspapers,
    journalistsBySentimentsForCompanies
  },
  newspapers: path('/newspapers'),
  signIn: path('auth/sign_in'),
  secureLink: path('auth/send_magic_link'),
  loginWithMagicLink: path('auth/sign_in_using_magic_link'),
  resetPassword: path('auth/reset_password'),
  setPassword: path('auth/set_password_using_invitation'),
  changePassword: path('auth/change_password'),
  userInfo: path('user/info'),
  signUp: path('user/register'),
  payment: path('user/payment'),
  userInfoUpdate: path('user/update'),
  changePasswordUniversalLink: path('auth/change_password_using_token'),
  myNews: path('my-news'),
  unauthNews: path('contents/demo'),
  briefingsLabels: path('briefings/types'),
  followedBriefingTypes: path('briefings/types/followed'),
  companiesLabels: path('companies/sectors'),
  followedHashtags: path('hashtags/followed'),
  followedCompanies: path('companies/followed'),
  followedBriefings: path('contents/for_followed/briefing_type'),
  jobApplication: path('contact/send_job_application'),
  requestDemo: path('contact/request_demo'),
  requestDemo350Club: path('contact/request_demo_350_club'),
  subscriptionPlans: path('stripe/plans'),
  acceptTrial: path('contracts/accept_trial'),
  subscriptions: path('stripe/subscriptions'),
  userSubscriptions: path('user/subscriptions'),
  stripeUnsubscribe: path('stripe/unsubscribe'),
  cards: path('stripe/cards'),
  defaultCard: path('stripe/cards/default'),
  contactUs: path('contact/contact_us'),
  articlesSummary,
  articlesCoverage,
  articlesContent,
  briefings,
  follows,
  contents,
  contentsForChildren,
  contentsForClientCoverage,
  companiesGroups,
  companySections,
  search,
  unsubscribe,
  companies,
  companyDetails,
  companyPublications,
  resuestDetailedReport: path('reports/content_publications_report'),
  employees: path('cms/employees'),
};
