import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid';
import Text from './Text';
import Button from './Button';
import Container from './Container';
import Svg from './Svg';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#app');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '35px 50px',
    width: '100%',
    maxWidth: '486px',
    borderRadius: 'none'
  },
  overlay: {
    zIndex: 10000
  }
};

export default class PrimaryModal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    showClose: PropTypes.bool,
  };

  static defaultProps = {
    showClose: true,
    content: null,
  }

  render() {
    const {
      title, content, children, isOpen, onClose, showClose
    } = this.props;
    return (
      <Container px={15}>
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          style={customStyles}
        >
          <Flex justifyContent="flex-end">
            <Svg
              src="/images/icons/icon-cross-blue.svg"
              maxHeight={16}
              width={16}
              mb={-8}
              alt="X"
              onClick={onClose}
              className="clickable"
            />
          </Flex>
          <Text mb={26} textAlign="left" fontSize={26} color="#530C74">
            {title}
          </Text>
          <Text textAlign="left" color="#505A6B" mb={30}>
            {content}
          </Text>
          {children}
          {showClose
            && (
            <Flex justifyContent="center" mt={26}>
              <Button onClick={onClose}>Close</Button>
            </Flex>
            )}
        </Modal>
      </Container>
    );
  }
}
