import {
  UNAUTH_FORM_REQUEST,
  UNAUTH_FORM_RESET,
  UNAUTH_FORM_SUCCESS,
  UNAUTH_FORM_FAILURE
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  message: '',
  errors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UNAUTH_FORM_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UNAUTH_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.message
      };
    case UNAUTH_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case UNAUTH_FORM_RESET:
      return initialState;
    default:
      return state;
  }
};
