import omit from 'lodash/omit';
import { push } from 'connected-react-router';
import { APP_ROUTES } from '../constants/routes';
import { fetchUserInfo } from './account';
import {
  UNAUTH_FORM_REQUEST,
  UNAUTH_FORM_RESET,
  UNAUTH_FORM_SUCCESS,
  UNAUTH_FORM_FAILURE,
  LOGIN_SUCCES
} from './actionTypes';
import API, { setAuthenticationToken } from '../config/api';
import apiRoutes from '../config/apiRoutes';

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

const formRequest = () => ({
  type: UNAUTH_FORM_REQUEST
});
const formSuccess = (message) => ({
  type: UNAUTH_FORM_SUCCESS,
  message
});
const formFailure = (errors) => ({
  type: UNAUTH_FORM_FAILURE,
  errors
});

export const demoRequest = (data) => async (dispatch) => {
  dispatch(formRequest());
  try {
    await API.post(apiRoutes.requestDemo, data);
    dispatch(
      formSuccess('We will be in touch shortly to introduce ourselves.')
    );
  } catch (error) {
    dispatch(formFailure(error.errors));
  }
};

export const demoRequest350Club = (data) => async (dispatch) => {
  dispatch(formRequest());
  try {
    await API.post(apiRoutes.requestDemo350Club, data);
    dispatch(
      formSuccess('We will be in touch shortly to introduce ourselves.')
    );
  } catch (error) {
    dispatch(formFailure(error.errors));
  }
};

export const createAccount = (data) => async (dispatch) => {
  dispatch(formRequest());
  try {
    const response = await API.post(apiRoutes.signUp, data);
    setAuthenticationToken(response.data.mobile_authentication_token);
    dispatch({ type: LOGIN_SUCCES });
    dispatch(fetchUserInfo());
    dispatch(formSuccess('Your account is now ready to use.'));
    dispatch(push(APP_ROUTES.startTrial));
  } catch (error) {
    dispatch(formFailure(error.errors));
  }
};

export const applyJob = (formData) => async (dispatch) => {
  dispatch(formRequest());
  const { attachment } = formData;
  const data = omit(formData, ['attachment']);
  if (attachment) {
    const content = await getBase64(attachment);
    // Mandrill expects string without prefix base64
    data.attachment = {
      type: attachment.type,
      name: attachment.name,
      content: content.split('base64,')[1]
    };
  }
  try {
    await API.post(apiRoutes.jobApplication, data);
    return dispatch(formSuccess('Your application has been successfully sent!'));
  } catch ({ errors }) {
    if (!errors) {
      return dispatch(formFailure({
        attachment: ['File is too large. Maximum file size is 20MB.'],
      }));
    }
    return dispatch(formFailure(errors));
  }
};

export const resetForm = () => ({
  type: UNAUTH_FORM_RESET
});

export const contactUs = (data) => async (dispatch) => {
  dispatch(formRequest());
  try {
    await API.post(apiRoutes.contactUs, data);
    dispatch(formSuccess('Thank you! Your message has been sent successfully.'));
  } catch (error) {
    dispatch(formFailure(error.errors));
  }
};
