import { history } from './store';

const { NODE_ENV, REACT_APP_INTERCOM_APP_ID } = process.env;

export const bootIntercom = (state) => {
  if (NODE_ENV !== 'test') {
    const { account: { data: { email, intercomHash } } } = state;
    window.Intercom('boot', {
      app_id: REACT_APP_INTERCOM_APP_ID,
      user_hash: intercomHash,
      email,
    });
  }
};
export const shutdownIntercom = () => {
  if (NODE_ENV !== 'test') {
    window.Intercom('shutdown');
  }
};

export const initializeIntercom = (userData) => {
  let intercomSettings = {
    app_id: REACT_APP_INTERCOM_APP_ID,
  };
  if (userData.email && userData.intercomHash) {
    intercomSettings = {
      ...intercomSettings,
      email: userData.email,
      user_hash: userData.intercomHash
    };
  }
  window.Intercom('boot', intercomSettings);
  history.listen(() => {
    window.Intercom('update');
  });
};
