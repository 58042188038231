import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  display: inline-block;
  background: ${(props) => props.theme.color.buttonSecondaryBg};
  border: 1px solid ${(props) => props.theme.color.buttonPrimaryBg};
  color: ${(props) => props.theme.color.buttonPrimaryBg};
  line-height: auto;
  padding: 14px 20px;
  font-size: 18px;
  border-radius: 25px;
  margin-left: ${(props) => props.ml}px;
  &:hover {
    cursor: pointer;
  }
`;
const Radio = styled.input`
  visibility: hidden;
  position: absolute;
  :checked + ${/* sc-s */Label} {
    border: 1px solid ${(props) => props.theme.color.buttonPrimaryBg};
    background-color: ${(props) => props.theme.color.buttonPrimaryBg};
    color: #fff;
  }
`;

export const RadioRoundedButton = ({
  id, label, name, inputFormProps, ml
}) => (
  <>
    <Radio name={name} id={id} type="radio" {...inputFormProps} />
    <Label htmlFor={id} ml={ml}>{label}</Label>
    {/* meta props for error handling below */}
  </>
);
