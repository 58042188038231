import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const TextLink = styled(({
  white, big, inheritColor, inheritFontSize, withArrow, children, ...rest
}) => (
  <Link {...rest}>{children}</Link>
))`
  color: ${(props) => (props.white ? props.theme.color.fontOnDarkBgColor : props.theme.color.linkColor)};
  font-size: ${(props) => (props.big ? '20px' : '16px')};
  text-decoration: none;
  ${({ inheritColor }) => (inheritColor ? 'color: inherit;' : '')}
  ${({ inheritFontSize }) => (inheritFontSize ? 'font-size: inherit;' : '')}
  ${({ withArrow }) => withArrow && `
    padding-right: 22px;
    background-image: url('/images/icons/icon-arrow-blue.svg');
    background-size: 16px 12px;
    background-repeat: no-repeat;
    background-position: center right;
  `}
`;

export default TextLink;

export const LeftArrowLink = css`
  position: relative;
  padding: 0 0 0 20px;
  color: ${(props) => props.theme.color.linkColor};
  cursor: pointer;
  :before {
    position: absolute;
    top: 50%;
    left: 0;
    content: "";
    width: 10px;
    height: 10px;
    margin-top: -3px;
    border-left: 3px solid ${(props) => props.theme.color.linkColor};
    border-bottom: 3px solid ${(props) => props.theme.color.linkColor};
    transform: rotate(45deg) translateY(-50%);
  }
`;

export const SimpleLink = styled.a`
  cursor: pointer;
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  text-align: ${(props) => props.textAlign};
  display: block;
  :hover {
    text-decoration: underline;
  }
`;

SimpleLink.defaultProps = {
  mt: 0,
  mb: 0,
  textAlign: 'left',
};

SimpleLink.propTypes = {
  mt: PropTypes.number,
  mb: PropTypes.number,
  textAlign: PropTypes.string,
};
