import {
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  errors: {},
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        list: action.list,
        isLoading: false
      };
    default:
      return state;
  }
};
