import {
  LOGIN_SUCCES,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT_USER,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  SECURE_LINK_REQUEST,
  SECURE_LINK_SUCCESS,
  SECURE_LINK_FAILURE,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_EMAIL_REQUEST,
  RESET_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_EMAIL_FAILURE,
  COPY_EMAIL_TO_SECURE_LINK_PAGE,
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  isAuthenticated: !!localStorage.getItem('token'),
  errors: {},
  passwordChange: {
    errors: {},
    isLoading: false
  },
  secureLink: {
    errors: [],
    isLoading: false,
    email: '',
  },
  resetPasswordEmail: {
    errors: [],
    isLoading: false,
    success: false,
  },
  passwordSet: {
    errors: [],
    isLoading: false,
    success: false,
  },
  passwordReset: {
    errors: [],
    isLoading: false,
    success: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case LOGIN_SUCCES:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errors: {}
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errors: action.errors
      };
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        passwordChange: {
          isLoading: true
        }
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChange: {
          isLoading: false,
          errors: {}
        }
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        passwordChange: {
          isLoading: false,
          errors: action.errors
        }
      };
    case SECURE_LINK_REQUEST:
      return {
        ...state,
        secureLink: {
          ...state.secureLink,
          isLoading: true,
          errors: {},
        }
      };
    case SECURE_LINK_SUCCESS:
      return {
        ...state,
        secureLink: {
          ...state.secureLink,
          email: action.email,
          isLoading: false,
          errors: {},
        }
      };
    case SECURE_LINK_FAILURE:
      return {
        ...state,
        secureLink: {
          ...state.secureLink,
          email: '',
          isLoading: false,
          errors: action.errors
        }
      };
    case COPY_EMAIL_TO_SECURE_LINK_PAGE:
      return {
        ...state,
        secureLink: {
          ...state.secureLink,
          email: action.email
        }
      };
    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        passwordSet: {
          ...state.passwordSet,
          isLoading: true,
          errors: [],
          success: false
        }
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordSet: {
          ...state.passwordSet,
          isLoading: false,
          errors: [],
          success: true
        }
      };
    case SET_PASSWORD_FAILURE:
      return {
        ...state,
        passwordSet: {
          ...state.passwordSet,
          isLoading: false,
          errors: action.errors,
          success: false
        }
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isLoading: true,
          errors: [],
          success: false
        }
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isLoading: false,
          errors: [],
          success: true
        }
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isLoading: false,
          errors: action.errors,
          success: false
        }
      };
    case RESET_PASSWORD_EMAIL_REQUEST:
      return {
        ...state,
        resetPasswordEmail: {
          ...state.resetPasswordEmail,
          isLoading: true,
          errors: [],
          success: false
        }
      };
    case RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        resetPasswordEmail: {
          ...state.resetPasswordEmail,
          isLoading: false,
          errors: [],
          success: true
        }
      };
    case RESET_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        resetPasswordEmail: {
          ...state.resetPasswordEmail,
          isLoading: false,
          errors: action.errors,
          success: false
        }
      };
    default:
      return state;
  }
};
